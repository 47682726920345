import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import themeConfig from "../../lib/theme/tokens";
import HeroSectionImage from "../../assets/images/hero-section-image.svg";
import {
  StyledBookADemoButtonNavbar,
  StyledLoginButtonNavbar,
} from "../../lib/theme/components/Layout";
import { Image } from "antd";
import Fireworks from "../../assets/images/Fireworks.gif";
import { useNavigate } from "react-router-dom";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { useStore } from "zustand";
import { useFireworkStore } from "../../store/fireworkStore";
import { useMixpanel } from "react-mixpanel-browser";

const StyledHeroContainer = styled.div<{$isMobile: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: ${({ $isMobile }) => ($isMobile ? "120px 0px 69px 0px" : "66px 0px 69px 0px")};
  background: linear-gradient(180deg, #f6f9ff 0%, #ffffff 100%);
`;

const StyledRasmalAcquired = styled.span`
  border-radius: 20px;
  padding: 4px;
  width: auto;
  background-color: ${themeConfig.colors.primary[50]};
  color: ${themeConfig.colors.primary[500]};
  font-size: 14px;
  font-weight: 600;
`;

const StyledHeroTitle = styled.h1`
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  text-align: center;
  margin-bottom: 16px;
  color: ${themeConfig.colors.natural[800]};
`;

const StyledHeroDescription = styled.p`
  width: 40%;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: ${themeConfig.colors.secondary};
`;

const StyledBookAButtonHero = styled(StyledBookADemoButtonNavbar)`
  padding: 18px 28px 18px 28px;
  border-radius: 8px;
  font-size: 18px;
`;

const StyledLoginButtonHero = styled(StyledLoginButtonNavbar)`
  padding: 18px 28px 18px 28px;
  border-radius: 8px;
  font-size: 18px;
`;

const StyledFireworksTopRight = styled.div`
  position: absolute;
  top: 0;
  right: 5%;
  background-color: transparent;
  width: 30%;
  height: 30%;
  background-image: url(${Fireworks});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  opacity: 0.5;
`;

const StyledFireworksBottomLeft = styled.div`
  position: absolute;
  bottom: 50%;
  left: 5%;
  background-color: transparent;
  width: 30%;
  height: 30%;
  background-image: url(${Fireworks});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  opacity: 0.5;
`;

const StyledImageHeroContainer = styled.div`
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: -5%;
        left: 15%;
        width: 15vh;
        height: 15vh;
        border: 144px solid #25B06D;
        border-radius: 50%;
        z-index: 0;

        @media (max-width: 1180px) {
            top: -15%;
            left: 15%;
            width: 10vh;
            height: 10vh;
            border: 144px solid #25B06D;
        }


        @media (max-width: 768px) {
            top: -25%;
            left: 15%;
            width: 50px;
            height: 50px;
            border: 64px solid #25B06D;
        }
    }
`;

const HeroSection = forwardRef((props: any, ref: any) => {
  const [windowDimensions] = useState(getWindowDimensions());
  const navigate = useNavigate();
  const isFireworksOn = useFireworkStore((state: any) => state.firework.isFireworkOn);
  const setIsFireworkOn = useFireworkStore((state: any) => state.setIsFireworkOn);
  const fireworksTimer = 60000;
  const mixpanel = useMixpanel();

  const handleFireworksTimer = () => {
    console.log("is fireworks on", isFireworksOn)
    setTimeout(() => {
      setIsFireworkOn({
        isFireworkOn: false,
      });
    }, fireworksTimer);
  };

  useEffect(() => {
    handleFireworksTimer();
  }, []);

  return (
    <StyledHeroContainer $isMobile={windowDimensions.width <= 768} ref={ref} id="home">
      {isFireworksOn && (
        <>
          <StyledFireworksTopRight />
          <StyledFireworksBottomLeft />
        </>
      )}
      <StyledRasmalAcquired>
        ✨ Pentugram is now part of Rasmal Company ✨
      </StyledRasmalAcquired>
      <StyledHeroTitle style={{fontSize: windowDimensions.width > 768 ? "60px" : "36px", lineHeight: "44px"}}>Simplify The Investment Process</StyledHeroTitle>
      <StyledHeroDescription
        style={{ width: windowDimensions.width > 768 ? "768px" : "90%" }}
      >
        Managing an investment portfolio can be stressful, we understand the
        urgency of having accurate and comprehensible data whenever it is needed
      </StyledHeroDescription>
      <div
        style={{
          display: "flex",
          gap: "16px",
          marginBottom: "68px",
          marginTop: "43px",
        }}
      >
        <StyledBookAButtonHero onClick={() => {mixpanel.track(`Book a demo - LP - first block`); navigate("/book-a-demo")}}>
          Book a demo
        </StyledBookAButtonHero>
        <StyledLoginButtonHero
          onClick={() => {mixpanel.track(`Login - LP - first block`); window.location.href = "https://app.pentugram.com"}}
        >
          Login
        </StyledLoginButtonHero>
      </div>
      <StyledImageHeroContainer>
        <Image preview={false} src={HeroSectionImage} />
      </StyledImageHeroContainer>
    </StyledHeroContainer>
  );
});

export default HeroSection;
