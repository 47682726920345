const fonts = {
  fontSizeHeading1: "38px",
  fontSizeHeading2: "30px",
  fontSizeHeading3: "24px",
  fontSizeHeading4: "20px",
  fontSizeHeading5: "16px",
  fontSizeNormal: "14px",
  fontSizeXS: "12px",
  fontSizeXSM: "10px",
  fontSizeXXSM: "8px",
  fontWeightNormal: 400,
  fontWeightMD: 500,
  fontWeightBold: 600
};

export default fonts;
