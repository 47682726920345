import {
  Section,
  StyledSectionHeader,
  StyledSectionSubHeader,
} from "../../shared/components/Section";
import WhyPentugramSectionImage from "../../assets/images/why-pentugram-image.svg";
import { Image } from "antd";
import styled from "styled-components";
import themeConfig from "../../lib/theme/tokens";
import WhyPentugramHouse from "../../assets/images/why-pentugram-house.svg";
import WhyPentugramGuard from "../../assets/images/why-pentugram-guard.svg";
import WhyPentugramKey from "../../assets/images/why-pentugram-key.svg";
import WhyPentugramGear from "../../assets/images/why-pentugram-gear.svg";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { forwardRef, useState } from "react";

const StyledWhyPentugramContainer = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 32px;
  justify-content: start;
  align-items: start;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: ${themeConfig.colors.natural[800]};
`;

const StyledTextSubTitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  width: 80%;
  margin: 8px 0px;
  color: ${themeConfig.colors.natural[700]};
`;

const WhyPentugramSection = forwardRef((props: any, ref: any) => {
  const [windowDimensions] = useState(getWindowDimensions());

  return (
    <Section
      ref={ref}
      id="why-pentugram"
      $padding={
        windowDimensions.width > 768
          ? "96px 112px 96px 112px"
          : "96px 50px 96px 50px"
      }
      style={{ textAlign: "left" }}
    >
      <div
        style={{
          textAlign: "left",
          width: "100%",
          padding: "0px 32px 0px 32px",
        }}
      >
        <StyledSectionHeader>Why Pentugram</StyledSectionHeader>
        <StyledSectionSubHeader
          style={{ width: windowDimensions.width > 768 ? "50%" : "" }}
        >
          Managing an investment portfolio can be stressful, we understand the
          urgency of having accurate and comprehensible data whenever it is
          needed
        </StyledSectionSubHeader>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: windowDimensions.width > 768 ? "row" : "column",
          gap: "32px",
          textAlign: "left",
          marginTop: "64px",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Image
          preview={false}
          width={windowDimensions.width > 768 ? "40%" : "100%"}
          src={WhyPentugramSectionImage}
        />
        <div>
          <StyledWhyPentugramContainer>
            <span>
              <img src={WhyPentugramHouse} alt="" />
            </span>{" "}
            <StyledTextContainer>
              <StyledTextTitle>One-Stop-Shop for your Data</StyledTextTitle>

              <StyledTextSubTitle>
                Onboard your Potential Investments, filter and create a
                customizable pipeline without effort.
              </StyledTextSubTitle>
            </StyledTextContainer>
          </StyledWhyPentugramContainer>
          <StyledWhyPentugramContainer>
            <span>
              <img src={WhyPentugramGuard} alt="" />
            </span>{" "}
            <StyledTextContainer>
              <StyledTextTitle>Your Data is Secured</StyledTextTitle>

              <StyledTextSubTitle>
                Onboard your Potential Investments, filter and create a
                customizable pipeline without effort.
              </StyledTextSubTitle>
            </StyledTextContainer>
          </StyledWhyPentugramContainer>
          <StyledWhyPentugramContainer>
            <span>
              <img src={WhyPentugramKey} alt="" />
            </span>{" "}
            <StyledTextContainer>
              <StyledTextTitle>You’ll Find it Easy To Use</StyledTextTitle>

              <StyledTextSubTitle>
                Onboard your Potential Investments, filter and create a
                customizable pipeline without effort.
              </StyledTextSubTitle>
            </StyledTextContainer>
          </StyledWhyPentugramContainer>
          <StyledWhyPentugramContainer>
            <span>
              <img src={WhyPentugramGear} alt="" />
            </span>{" "}
            <StyledTextContainer>
              <StyledTextTitle>Customize It To Your Needs</StyledTextTitle>

              <StyledTextSubTitle>
                Onboard your Potential Investments, filter and create a
                customizable pipeline without effort.
              </StyledTextSubTitle>
            </StyledTextContainer>
          </StyledWhyPentugramContainer>
        </div>
      </div>
    </Section>
  );
});

export default WhyPentugramSection;
