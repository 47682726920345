import styled from "styled-components";
import themeConfig from "../tokens";
import { StyledBookADemoButtonNavbar } from "./Layout";
import { CheckOutlined, LineChartOutlined } from "@ant-design/icons";

export const StyledFeautreDescription = styled.p`
    font-size: 18px;
    font-weight: 400;
    color: ${themeConfig.colors.natural[500]};
    text-align: left;
`;

export const StyledFeatureTitle = styled.h2`
    font-size: 32px;
    font-weight: 700;
    color: ${themeConfig.colors.natural[800]};
    margin-bottom: 16px;
    text-align: left;
`;

export const StyledBookADemoFeature = styled(StyledBookADemoButtonNavbar)`
    padding: 18px 28px 18px 28px;
`;

export const StyledUnorderListItem = styled.span`
    color: ${themeConfig.colors.natural[700]};
    font-weight: 400;
    font-size: 18px;
`;

export const StyledCheckOutlined = styled(CheckOutlined)`
    color: ${themeConfig.colors.primary[500]};
    font-size: 18px;
    margin-right: 12px;
`;

export const StyledUnorderedList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: left;
    align-items: start;
    margin-bottom: 32px;
`;


export const StyledDealFeatureContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

`;

export const StyledLineChartOutlined = styled(LineChartOutlined)`
    font-size: 48px;
    color: ${themeConfig.colors.primary[500]};

`;

export const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
`;

export const StyledUnorderedListItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
  text-align: left;
`;



