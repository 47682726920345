import styled from "styled-components";

const StyledCardContainer = styled.div`
  border: 1px solid #e8e8ec;
  padding: 32px;
  height: 80%;
  border-radius: 16px;
  gap: 32px;
  text-align: left;
  box-shadow: 0px 1px 2px 0px #0409210d;
`;

function SecuritySectionCard({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: string;
}) {
  return (
    <StyledCardContainer>
      <div>
        <img src={icon} alt="icon" />
      </div>
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </StyledCardContainer>
  );
}

export default SecuritySectionCard;
