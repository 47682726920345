import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import HomePage from "./pages/Home/Home";
import { RouterProvider, createBrowserRouter, useLocation  } from "react-router-dom";
import BookADemoPage from "./pages/BookADemoPage/BookADemoPage";
import TermsPage from "./pages/TermsPage/TermsPage";
import FAQsPage from "./pages/FAQsPage/FAQsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import ScrollToTop from "./utils/ScrollToTop";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/book-a-demo",
    element: <BookADemoPage/>,
  },
  {
    path: "/terms-of-service",
    element: <TermsPage/>,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage/>,
  },
  {
    path: "/faqs",
    element: <FAQsPage/>,
  },
  {
    path: "contact",
    element: <ContactPage />,
  }
]);

function App() {


  return (
    <div className="App">
      {/* <ScrollToTop/> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
