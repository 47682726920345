import styled from "styled-components";
import { ReactComponent as NavbarIcon } from "../../assets/images/navbar-logo.svg";
import themeConfig from "../../lib/theme/tokens";
import { get } from "http";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { useState } from "react";
import { createWindowTab } from "../../utils/createWindowTab";
import { useNavigate } from "react-router-dom";

const StyledListTitle = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${themeConfig.colors.natural[500]};
`;

const StyledListItems = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: ${themeConfig.colors.natural[700]};
`;

const StyledAcquisitionText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${themeConfig.colors.natural[500]};
  font-weight: 400;
  width: 80%;
  text-align: start;
`;

function FooterSection() {
  const [windowDimensions] = useState(getWindowDimensions());
  const navigate = useNavigate();

  const desktopFooter = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "64px 112px 48px 112px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          justifyItems: "start",
          padding: "0px 32px 0px 32px",
        }}
      >
        <NavbarIcon />
        <StyledAcquisitionText>
          Pentugram is now part of Rasmal Company. Together, we’re stronger and
          ready to bring you even better services.
        </StyledAcquisitionText>
        <StyledAcquisitionText>
          Copyright ©2023 Pentugram Acquired by RasMal | All rights reserved.
        </StyledAcquisitionText>
      </div>
      <div style={{ display: "flex", gap: "32px", textAlign: "start" }}>
        <div>
          <StyledListTitle>product</StyledListTitle>
          <div>
            <StyledListItems onClick={() => createWindowTab("https://www.youtube.com/channel/UCDtiVtuUhTS-aUkWNXnDEAw")}>Tutorial</StyledListItems>
            <StyledListItems onClick={() => navigate("/faqs")}>FAQs</StyledListItems>
            <StyledListItems onClick={() => navigate("/contact")}>Contact</StyledListItems>
            <StyledListItems onClick={() => navigate("/book-a-demo")}>Book A Demo</StyledListItems>
          </div>
        </div>
        <div>
          <StyledListTitle>Legal</StyledListTitle>
          <div>
            <StyledListItems onClick={() => navigate("/terms-of-service")}>Terms</StyledListItems>
            <StyledListItems onClick={() => navigate("/privacy-policy")}>Privacy</StyledListItems>
          </div>
        </div>
        <div>
          <StyledListTitle>Social</StyledListTitle>
          <div>
            <StyledListItems onClick={() => createWindowTab("https://www.linkedin.com/company/pentugram/")}>Linkedin</StyledListItems>
            <StyledListItems onClick={() => createWindowTab("https://twitter.com/pentugram")}>Twitter</StyledListItems>
            <StyledListItems onClick={() => createWindowTab("https://www.facebook.com/pentugram/")}>Facebook</StyledListItems>
          </div>
        </div>
      </div>
    </div>
  );

  const mobileFooter = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "32px 16px 32px 16px",
      }}
    >
      <NavbarIcon />
      <StyledAcquisitionText style={{width: "100%"}}>
        Pentugram is now part of Rasmal Company. Together, we’re stronger and
        ready to bring you even better services.
      </StyledAcquisitionText>
      <div style={{ display: "flex", gap: "32px", flexWrap: "wrap", textAlign: "start" }}>
        <div>
          <StyledListTitle>product</StyledListTitle>
          <div>
            <StyledListItems onClick={() => createWindowTab("https://www.youtube.com/channel/UCDtiVtuUhTS-aUkWNXnDEAw")}>Tutorial</StyledListItems>
            <StyledListItems onClick={() => navigate("/faqs")}>FAQs</StyledListItems>
            <StyledListItems onClick={() => navigate("/contact")}>Contact</StyledListItems>
            <StyledListItems onClick={() => navigate("/book-a-demo")}>Book A Demo</StyledListItems>
          </div>
        </div>
        <div>
          <StyledListTitle>Legal</StyledListTitle>
          <div>
            <StyledListItems onClick={() => navigate("/terms-of-service")}>Terms</StyledListItems>
            <StyledListItems onClick={() => navigate("/privacy-policy")}>Privacy</StyledListItems>
          </div>
        </div>
        <div>
          <StyledListTitle>Social</StyledListTitle>
          <div>
            <StyledListItems onClick={() => createWindowTab("https://www.linkedin.com/company/pentugram/")}>Linkedin</StyledListItems>
            <StyledListItems onClick={() => createWindowTab("https://twitter.com/pentugram")}>Twitter</StyledListItems>
            <StyledListItems onClick={() => createWindowTab("https://www.facebook.com/pentugram/")}>Facebook</StyledListItems>
          </div>
        </div>
      </div>
      <StyledAcquisitionText style={{width: "100%"}}>
          Copyright ©2023 Pentugram Acquired by RasMal | All rights reserved.
        </StyledAcquisitionText>
    </div>
  );
  
  return windowDimensions.width > 768 ?  
   desktopFooter : mobileFooter;
}

export default FooterSection;
