import { useLocation } from "react-router-dom";
import FooterSection from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Newsbar from "../../components/Newsbar/Newsbar";
import "./TermsPage.css";
import { useEffect, useState } from "react";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";

function TermsPage() {

  const location = useLocation();
  const [windowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <Newsbar />
      <Navbar />
      <main style={{padding: windowDimensions.width > 768 ? "0 30px" : "70px 30px 0 30px"}}>
        <div>
          <h1 style={{ textAlign: "center" }}>Terms of Service</h1>
          <p style={{ textAlign: "center" }}>
            Our Terms of Services (ToS) are designed to be read and understood
            by the average person. Before using our service, please read them
            carefully and make sure you understand everything they say.
          </p>
        </div>
        <div style={{ textAlign: "left" }}>
          <h2>INTRODUCTION</h2>
          <div>
            <p>
              Welcome to Pentugram.com. Pentugram is a service that helps
              investment professionals to manage both their deal flow and their
              investment portfolios. Our objective is to make managing
              investments simpler and efficient. These Terms of Service govern
              your access to and use of Pentugram at www. Pentugram .com (the
              “Pentugram Website” or “Website”), as well as the information and
              materials on the Website (collectively, the “Content”, as further
              defined below). Please read these Terms of Service (the “Terms”)
              carefully as they form a contract between the owner of Pentugram
              User Account who uses the Service (“Account Owner”), and apply to
              the administrator of accounts on behalf of the account owner (the
              “Enterprise Administrator”), together with individuals who are
              provided access to the Pentugram User Account by the Account Owner
              and interact with, or direct the Pentugram Software in the
              performance of its functions (“Authorized Designees”),
              collectively, the “Users”.
            </p>
            <p>
              The Website is operated by Pentugram Holding Ltd (“Pentugram” or
              “we” or “us”). These Terms govern Users’ access and use of: (i)
              the hosted storage solution provided by Pentugram for online
              storage, sharing and processing of files, materials, data, text,
              audio, video, images or other content (collectively, “Content”);
              (ii) the Pentugram websites, applications or software provided or
              made available by Pentugram(the “Software”); and, (iii) any
              written or electronic investment information, valuation
              information, company data, company communications or related
              investor information provided or made available by Pentugram
              (collectively the “Service(s)”).
            </p>
            <p>
              BY REGISTERING OR USING ANY OF THE SERVICES, USERS AGREE TO BE
              BOUND BY THESE TERMS. AUTHORIZED DESIGNEES USING THE PENTUGRAM
              SERVICES ARE AGREEING TO THESE TERMS OF SERVICE ON THEIR OWN
              BEHALF. ENTERPRISE ADMINISTRATORS ARE AGREEING TO THESE TERMS FOR
              THEIR ORGANIZATION AND REPRESENTING TO PENTUGRAM THAT THEY HAVE
              THE AUTHORITY TO BIND THEIR ORGANIZATION TO THESE TERMS. USERS MAY
              USE THE SERVICES ONLY IN COMPLIANCE WITH THESE TERMS. IF A USER
              DOES NOT AGREE TO BE BOUND BY THESE TERMS, THE USER MUST NOT USE
              THE SERVICES. SHOULD A USER HAVE ANY QUESTIONS CONCERNING THESE
              TERMS, PLEASE CONTACT US VIA <a href="mailto:support@pentugram.com">SUPPORT@PENTUGRAM.COM</a>
            </p>
            <p>
              Please note that <strong>Pentugram</strong> doesn’t provide, and
              specifically disclaims, warranties for the Services. These Terms
              also limits our liability to Users. See Sections 15 and 16 for
              details.
            </p>
            <ol>
              <li>
                <strong>ACCESS TO THE SERVICE</strong>
                <p>
                  Users may use the Service, on a non-exclusive basis, solely in
                  strict compliance with these Terms and all applicable laws.
                </p>
              </li>
              <li>
                <strong>USER ACCOUNTS</strong>
                <p>
                  To obtain access to certain Services, you are required to
                  obtain an account with Pentugram or be an Enterprise
                  Administrator or Authorized Designee. The Enterprise
                  Administrator on behalf of the Account Owner must complete a
                  registration form, designating a User ID and password and
                  providing credit card information or making other payment
                  arrangements as specified below. Until the Account Owner
                  applies for and is approved for a Pentugram User Account,
                  access to the Service will be limited to the areas of the
                  Service, if any, that Pentugram makes available to the general
                  public. When registering with Pentugram , the Account Owner or
                  the Enterprise Administrator on behalf of the Account Owner
                  must: (a) provide true, accurate, current and complete
                  information about the Account Owner as requested by the
                  Service’s registration form (such information being the
                  “Registration Data”) (b) maintain and promptly update the
                  Registration Data to keep it true, accurate, current and
                  complete, (c) work with Pentugram (i) to assist in the
                  uploading of Account Owner data to the system, if applicable,
                  and (ii) to review the accuracy and completeness of data
                  existing in the Service which pertains to the Account Owner
                  and (d) promptly notify Pentugram if you believe or have
                  reason to believe any of the data in the Service which
                  pertains to the Account Owner is or may be incorrect.
                </p>
                <p>
                  Only an Account Owner, an Enterprise Administrator, or an
                  Authorized Designee who lawfully possesses an Account
                  Ownerâ€™s or Enterprise Administratorâ€™s User ID and password
                  may use a Pentugram account. Users must keep the User ID and
                  password confidential and not allow any third party to access
                  or use the Service on their behalf. Users should immediately
                  change their password if they suspect misuse of their account.
                  Users should contact us immediately if they suspect any
                  security breach in the Service. Each Account Owner is
                  responsible for all activities of Enterprise Administrators
                  and Authorized Designees that take place with their account.{" "}
                  <strong>
                    {" "}
                    Pentugram will not be liable for any loss or damage arising
                    from any unauthorized use of accounts.
                  </strong>
                </p>
                <p>
                  It is the responsibility of the Users to protect their User ID
                  and password and to change their password as frequently as
                  they deem necessary. Pentugram shall have no responsibility to
                  verify the identity of any person accessing the website
                  through a valid User ID and password. In cases where an
                  Account Owner is an individual natural person, and that
                  Account Owner dies or becomes incapacitated, a family member
                  or other representative with lawful possession of their User
                  ID and password may access their account. For the avoidance of
                  doubt, such access shall not be permitted in cases where the
                  Account Owner is an entity rather than a natural person.{" "}
                  <strong>
                    If a third party representative of a deceased or
                    incapacitated individual natural person who was an Account
                    Owner who does not possess that Account Ownerâ€™s User ID
                    and password wishes to access that Account Ownerâ€™s
                    account, access will be DENIED UNLESS
                  </strong>{" "}
                  the representative: either (1) presents an original document
                  in which he or she is named as attorney-in-fact under a
                  durable power of attorney executed by the Account Owner within
                  the preceding 7 years, with broad powers to act on the Account
                  Ownerâ€™s behalf; or (2) the representative can prove s/he is
                  appointed as a financial fiduciary for the Account Owner by a
                  Court with jurisdiction over the Account Owner or his/her
                  estate (â€œPersonal Representative/ Conservator/Trusteeâ€) and
                  provides an original certificate of appointment issued by the
                  Court within the prior 90 days.
                </p>
              </li>
              <li>
                <strong>PAYMENTS</strong>
                <p>
                  The fees applicable for the Service (“Fees”) are available on
                  the Website and/or in Pentugram’s then-current published price
                  list. The price stated for the Service excludes all taxes and
                  charges, unless stated otherwise. Account Owners are
                  responsible for any taxes and for all other lawfully applied
                  charges (for example, personal data charges or currency
                  exchange settlements). In addition to any Fees, Account Owners
                  may still incur charges incidental to using the Service, for
                  example, charges for an Account Ownerâ€™s Internet access,
                  data roaming, and other data transmission charges. Pentugram
                  services must be paid in advance. Each Account Owner or
                  Enterprise Administrator on behalf of the Account Owner must
                  be authorized to use the payment method that they enter when
                  they create a billing account. Each Account Owner or
                  Enterprise Administrator authorizes us to charge them for the
                  Service using their supplied payment method and for any paid
                  feature of the Service that they choose to sign up for or use
                  while these Terms are in force. If the Account Owner chooses
                  to upgrade their Service plan or increase the number of Users
                  or fee-based features during payment term (a â€œSubscription
                  Upgradeâ€), any incremental fees associated with such
                  Subscription Upgrade will be prorated over the remaining
                  period of the current payment term, charged to the account and
                  due and payable upon implementation of such Subscription
                  Upgrade. In any future payment term, the Account Ownerâ€™s
                  fees will reflect any such Subscription Upgrades. If the
                  Account Owner elects to downgrade their Service, they may
                  cause loss of content, features, or capacity of the Service as
                  available to them and Pentugram does not accept any liability
                  for such loss.
                </p>
                <p>
                  We may bill: (a) in advance; (b) at the time of purchase; (c)
                  shortly after purchase; or (d) on a recurring basis for
                  subscription Services. Once we have informed an Account Owner
                  or Enterprise Administrator on behalf of the Account Owner
                  that the Service will be provided indefinitely or
                  automatically renewed, we may automatically renew their
                  Service and charge them for any renewal term. We will notify
                  the Account Owner or Enterprise Administrator on behalf of the
                  Account Owner in advance, either through the Service or to the
                  email address they have most recently provided to us, if we
                  change the price of the Service. If there’s a specific length
                  and price for a Service offer, that price will remain in force
                  for that time. After the offer period ends, continued use of
                  the Service will be charged at the new price. If a Service is
                  billed on a period basis (for example, monthly) with no
                  specific length, we will notify the Account Owner or
                  Enterprise Administrator on behalf of the Account Owner of any
                  price change at least 30 days in advance. If the Account Owner
                  doesn’t agree to these changes, they must cancel and stop
                  using the Service via cancelation functionality on the Website
                  or, if none is available, via an email to &nbsp;
                  <a href="mailto:support@pentugram.com">support@pentugram.com</a> (with cancellation confirmation from a
                  Pentugram representative) no later than fourteen (14) days
                  prior to the conclusion of their current payment term, whether
                  monthly, yearly, or otherwise. If the Account owner or
                  Enterprise Administrator on behalf of an Account Owner
                  cancels, their Service ends at the end of their current
                  Service period or, if we bill the Account ownerâ€™s account on
                  a period basis, at the end of the period in which the User
                  canceled. If a User fails to cancel as required, we will
                  automatically renew the Service for the same term and will
                  charge the Userâ€™s payment information on file with us
                  commencing on the first day of the renewal term.
                </p>
                <p>
                  Account Owners or Enterprise Administrators on behalf of
                  Account Owners must keep all information in their billing
                  account current. An Account Owner or Enterprise Administrator
                  on behalf of the Account Owner can access and modify their
                  billing account information using the Website. An Account
                  Owner or Enterprise Administrator on behalf of the Account
                  Owner may change their payment method at any time. If an
                  Account owner or Enterprise Administrator tells us to stop
                  using a payment method and we no longer receive payment from
                  the Account Owner for the paid Service, we may cancel that
                  Service. An Account Owner or Enterprise Administratorâ€™s
                  notice to us will not affect charges we submit to their
                  billing account before we reasonably could act on the request.
                </p>
                <p>
                  If an Account Owner or Enterprise Administrator pays by credit
                  card, the Website provides an interface for the Account Owner
                  or Enterprise Administrator to change credit card information
                  (e.g. upon card renewal). The Account Owner or Enterprise
                  Administrator will receive a receipt upon each receipt of
                  payment by Pentugram , or they may obtain a receipt from
                  Pentugram by request to{" "}
                  <a href="mailto:support@pentugram.com">
                    support@pentugram.com
                  </a>{" "}
                  to track subscription status. The Account Owner or Enterprise
                  Administrator on behalf of the Account Owner hereby authorizes
                  Pentugram to bill their credit card or other payment
                  instrument in advance on a periodic basis in accordance with
                  the terms of their Service until they terminate their
                  subscription. Pentugram uses a third-party intermediary to
                  manage credit card processing and this intermediary is not
                  permitted to store, retain or use an Account Ownerâ€™s or
                  Enterprise Administratorâ€™s billing information except as
                  necessary to process their credit card information for
                  Pentugram and for no other reason. Payments for accounts
                  registered to pay via credit card, are due the date the
                  invoice is posted on the Account Ownerâ€™s account; and all
                  Enterprise accounts registered to pay via check or
                  wire-transfer, are due within thirty (30) days of billing date
                  unless otherwise agreed-to by the parties in writing. Account
                  Owners or Enterprise Administrators on their behalf retain the
                  responsibility for settling all outstanding balances in a
                  timely manner and maintaining updated billing information. If
                  payment obligations are not complied with, at the end of 180
                  days, the Account Ownerâ€™s account will be deactivated and
                  all data will no longer be retrievable.
                </p>
                <p>
                  Except as specifically set forth in this section, all Services
                  are prepaid for the period selected (monthly, annually or
                  otherwise) and are non-refundable. This includes accounts that
                  are auto-renewed.
                </p>
                <p>
                  Except as prohibited by law, we may assess a late charge if
                  the Account Owner does not provide timely payment details or
                  pay on time. The Account Owner must pay these late charges
                  when we bill the Account Owner for them. The late charge will
                  be the lesser of 1 percent of the unpaid amount each month or
                  the maximum rate permitted by law. We may use a third party to
                  collect past due amounts. A delinquent Account Owner must pay
                  for all reasonable costs we incur to collect any past due
                  amounts. We may suspend or cancel Service if the Account Owner
                  fails to pay in full on time.
                </p>
              </li>
              <li>
                <strong>
                  CONSENT TO ELECTRONIC COMMUNICATIONS AND SOLICITATION
                </strong>
                <p>
                  By registering with Pentugram, the Account Owner or Enterprise
                  Administrator on behalf of the Account Owner understands that
                  we may send them communications or data regarding the
                  Services, including but not limited to (a) notices about use
                  of the Services, including any notices concerning violations
                  of use, (b) updates, and (c) promotional information and
                  materials regarding Pentugramâ€™s products and services, via
                  electronic mail. We give the Account Owner or the Enterprise
                  Administrator on behalf of the Account Owner the opportunity
                  to opt-out of receiving non-essential electronic mail from us
                  by following the opt-out instructions provided in the message.
                </p>
              </li>
              <li>
                <strong>CONTENT</strong>
                <p>
                  Except for material that we explicitly license to Users, we
                  don’t claim ownership of any Content Account Owners or
                  Enterprise Administrators provide on behalf of the Account
                  Owner that is transmitted, stored, or processed in User
                  account(s). We also don’t control, verify, or endorse the
                  Content that Account Owners, Authorized Designees or
                  Enterprise Administrators on behalf of Account Owners make
                  available on the Service, including investment information,
                  capitalization data, or other company financial data in User
                  accounts provided by, or derived from information provided by,
                  the companies in which Users have invested.
                </p>
                <p>
                  The Account Owner or Enterprise Administrator on behalf of the
                  Account Owner represents and warrants that: (a) they have all
                  the rights in the Content necessary for them to use the
                  Service and to grant the rights in this Section; and, (b) the
                  storage, use or transmission of the Content doesn’t violate
                  any law or these Terms (c) they will only store content
                  directly related to their investments and will not store
                  unrelated materials in their account.
                </p>
                <p>
                  Each Account Owner or Enterprise Administrator on behalf of
                  the Account Owner will: (a) be solely responsible for the
                  nature, quality and accuracy of the Content they upload; (b)
                  ensure that the Content (including the storage or transmission
                  thereof) complies with these Terms and any and all applicable
                  laws, and regulations; (c) promptly handle and resolve any
                  notices and claims relating to the Content, including any
                  notices sent to a User by any person claiming that any Content
                  violates any person’s rights, such as take-down notices
                  pursuant to the Digital Millennium Copyright Act and any other
                  notices; and (d) maintain appropriate security, protection and
                  backup copies of the Content. Pentugram will have no liability
                  of any kind as a result of the Account Ownerâ€™s or Enterprise
                  Administratorâ€™s deletion of, correction of, destruction of,
                  damage to, loss of or failure to store any Content.
                </p>
                <p>
                  Users must immediately notify Pentugram in writing of any
                  unauthorized use of any (a) Content (b) any Account or (c) the
                  Service that comes to a Userâ€™s attention. In the event of
                  any such unauthorized use by any third party that obtained
                  access through a User, that User will take all steps necessary
                  to terminate such unauthorized use. Users will provide
                  Pentugram with such cooperation and assistance related to any
                  such unauthorized use as Pentugram may reasonably request.
                </p>
              </li>
              <li>
                <strong>SUSPENSION AND TERMINATION OF THE SERVICE</strong>
                <ol>
                  <li>
                    <strong>System Maintenance</strong>
                    <p>
                      Although we will use all commercially reasonable efforts
                      to maintain the Service, minimize downtime, and avoid any
                      significant interruptions, in situations where downtime or
                      interruption is necessary, advisable or unavoidable for
                      maintenance or improvement of the system or otherwise, we
                      must and do reserve the right to temporarily suspend or
                      terminate User access to the Service at any time in our
                      sole discretion, with or without cause, and with or
                      without notice, without incurring liability of any kind.
                      For the avoidance of doubt, this includes liability to
                      Account Owners due to the revocation of their access or an
                      Enterprise Administratorâ€™s access to the Services.
                      Enterprise Administrators are responsible for complying
                      with these Terms of Service and shall bear any and all
                      liability to their Account Owners for their failure to
                      comply with these terms of service. If, in Pentugram’s
                      determination, the suspension might be lengthy enough to
                      be more than an inconvenience, and advance notice is
                      possible, Pentugram will use commercially reasonable
                      efforts to give advance notice to Users through the
                      Service and/or by means of email addresses provided by an
                      Account Owner or Enterprise Administrator on behalf of the
                      Account Owner.
                    </p>
                  </li>
                  <li>
                    <strong>Account Termination</strong>
                    <p>
                      Pentugram reserves the right to suspend or terminate
                      Usersâ€™ access to or use of the Service for: (a) the
                      actual or suspected (with reasonable foundation) violation
                      of these Terms; (b) the use of the Services in a manner
                      that may cause Pentugram to have legal liability or
                      disrupt others’ use of the Services; (c) the suspicion or
                      detection of any malicious code, virus or other harmful
                      code by a User, or in their account; (d) use of excessive
                      storage capacity or bandwidth; (e) unplanned technical
                      problems and outages; (f) conduct, or other behavior that
                      puts the service at risk or is or could be detrimental to
                      other Users. If, in Pentugram’s determination, the
                      suspension might be indefinite and/or Pentugram has
                      elected to terminate a Userâ€™s access to the Service,
                      Pentugram will use commercially reasonable efforts to give
                      notice (or where possible, advance notice) to Users
                      through the Service and/or by means of email addresses
                      provided by an Account Owner or Enterprise Administrator
                      on behalf of the Account Owner.{" "}
                      <strong>
                        Users acknowledge that if their access to the Service is
                        suspended or terminated for the reasons enumerated in
                        this paragraph, they may no longer have access to the
                        Content that is stored with the Service.
                      </strong>
                    </p>
                  </li>
                  <li>
                    <strong>Return of Content</strong>
                    <p>
                      Upon termination by Pentugram, and upon the request of an
                      Account Owner or an Enterprise Administrator on behalf of
                      the Account Owner to gain access to their Content, we will
                      use all commercially reasonable efforts to make available
                      such Content. We will use commercially reasonable efforts
                      to provide the Content without delay, and without charge.
                      In cases where the cost of providing the Content is
                      burdensome, we may in our sole discretion charge an
                      additional reasonable fee for access to this Content
                      reflecting our actual and reasonable costs of arranging
                      access. If the termination was for one of the causes
                      enumerated in the paragraph above, Pentugram shall have
                      the option, but not the obligation to provide the Account
                      Owner their Content. An Account Owner or Enterprise
                      Administrator on behalf of the Account Owner must make a
                      request for Content within (180) days following
                      termination. Otherwise, any Content the Account Owner has
                      stored with the Service may not be retrievable, and we
                      will have no obligation to maintain any Content or data
                      stored in the account.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <strong>ACCEPTABLE USE</strong>
                <p>
                  Users must not use the Service to harm others or the Service.
                  For example, Users must not use the Service to harm, threaten,
                  or harass another person, organization, or Pentugram and/or to
                  build a similar service or website. Users must not: damage,
                  disable, overburden, or impair the Service (or any network
                  connected to the Service); resell or redistribute the Service
                  or any part of it; use any unauthorized means to modify,
                  reroute, or gain access to the Service or attempt to carry out
                  these activities; or use any automated process or Service
                  (such as a bot, a spider, or periodic caching of information
                  stored by Pentugram) to access or use the Service. In
                  addition, Users promise that they will not and will not
                  encourage or assist any third party to:
                </p>
                <p>
                  a) modify, alter, tamper with, repair or otherwise create
                  derivative works of any Software;
                </p>
                <p>
                  b) reverse engineer, disassemble or decompile the software
                  used to provide or access the Service, including the Software,
                  or attempt to discover or recreate the source code used to
                  provide or access the Service, except and only to the extent
                  that the applicable law expressly permits doing so;
                </p>
                <p>
                  c) use the Service in any manner or for any purpose other than
                  as expressly permitted by these Terms, the Privacy Policy or
                  any other policy, instruction or terms applicable to the
                  Service that are available on the Service (“
                  <strong>Policies</strong>“);
                </p>
                <p>
                  d) sell, lend, rent, resell, lease, sublicense or otherwise
                  transfer any of the rights granted to them with respect to the
                  Services to any third party, except in the case of Enterprise
                  Administrators who are entitled to share information with
                  their group member accounts;
                </p>
                <p>
                  e) remove, obscure or alter any proprietary rights notice
                  pertaining to the Service;
                </p>
                <p>
                  f) access or use the Service in a way intended to improperly
                  avoid incurring fees or exceeding usage limits or quotas;
                </p>
                <p>
                  g) use the Service to: (i) engage in any unlawful or
                  fraudulent activity or perpetrate a hoax or engage in phishing
                  schemes or forgery or other similar falsification or
                  manipulation of data; (ii) send unsolicited or unauthorized
                  junk mail, spam, chain letters, pyramid schemes or any other
                  form of duplicative or unsolicited messages, whether
                  commercial or otherwise; (iii) advertise or promote a
                  commercial product or service that is not available through
                  Pentugram; (iv) store or transmit inappropriate Content, such
                  as Content: (1) containing unlawful, defamatory, threatening,
                  pornographic, abusive, libelous or otherwise objectionable
                  material of any kind or nature, (2) containing any material
                  that encourages conduct that could constitute a criminal
                  offense, or (3) that violates the intellectual property rights
                  or rights to the publicity or privacy of others; (iv) store or
                  transmit any Content that contains or is used to initiate a
                  denial of service attack, software viruses or other harmful or
                  deleterious computer code, files or programs such as Trojan
                  horses, worms, time bombs, cancelbots, or spyware; or (v)
                  abuse, harass, stalk or otherwise violate the legal rights of
                  a third party;
                </p>
                <p>
                  h) interfere with or disrupt servers or networks used by
                  Pentugram to provide the Service or used by other users’ to
                  access the Service, or violate any third party regulations,
                  policies or procedures of such servers or networks or harass
                  or interfere with another user’s full use and enjoyment of any
                  Software or the Service;
                </p>
                <p>
                  i) access or attempt to access Pentugram ‘s other accounts,
                  computer systems or networks not covered by these Terms,
                  through password mining or any other means;
                </p>
                <p>
                  j) cause, in Pentugram’s sole discretion, inordinate burden on
                  the Service or Pentugram’s system resources or capacity; or
                </p>
                <p>
                  k) share passwords or other access information or devices or
                  otherwise authorize any third party to access or use the
                  Software or the Service, except in the case of Account
                  Ownersâ€™ Authorized Designees or Enterprise Administrators
                  who are entitled to share information with their group member
                  accounts.
                </p>
                <p>
                  Pentugram reserves the right, in its sole discretion, to
                  deactivate, change and/or require an Account Owner (or an
                  Enterprise Administrator on behalf of an Account Owner where
                  applicable) to change their Pentugram User ID if necessary or
                  advisable. Pentugram may exercise such right at any time, with
                  or without prior notice. We will make all judgments concerning
                  the applicability of these guidelines in our sole and
                  exclusive discretion. We reserve the right, in our sole
                  discretion, to determine whether and what action to take in
                  response to each such notification, and any action or inaction
                  in a particular instance will not dictate or limit our
                  response to a future complaint. We will not assume or have any
                  liability for any action or inaction with respect to any
                  Content.
                </p>
              </li>
              <li>
                <strong>MODIFICATION TO THE SERVICE</strong>
                <p>
                  Pentugram will use commercially reasonable efforts to minimize
                  disruptions or end-of-life features relied on by Users, but
                  Pentugram reserves the right, in its sole discretion, to make
                  necessary unscheduled deployments of changes, updates or
                  enhancements to the Service at any time. If necessary, we may
                  add or remove functionalities or features, and we may suspend
                  or stop a Service altogether.
                </p>
              </li>
              <li>
                <strong>SOFTWARE</strong>
                <p>
                  If a User receives Software or access to Software from us, its
                  use is governed in one of two ways: If a User is presented
                  with service terms that they must accept in order to use the
                  Software, those service terms apply; if no terms are presented
                  to a User, these Terms apply. We reserve all other rights to
                  the Software. We may automatically check a Userâ€™s version of
                  the Software or the Userâ€™s web browser. We may also
                  automatically download to a Userâ€™s computer or device new
                  versions of the Software for use with newer web browsers. Any
                  Software is used with permission not licensed, or sold. Unless
                  we notify an Account Owner otherwise, the right to use the
                  Software ends when Service ends. Where necessary and if
                  requested and provided with instructions, Account Owners must
                  then promptly uninstall the Software, or if possible we will
                  disable it for Users remotely. Users must not work around any
                  technical limitations in the Software.
                </p>
              </li>
              <li>
                <strong>THIRD PARTY SERVICES AND CONTENT</strong>
                <p>
                  All transactions using Pentugramâ€™s services are between us
                  and the Account Owner or us and the Enterprise Administrator
                  on behalf of the Account Owner. The Services may contain
                  features and functionalities linking Users or providing Users
                  with certain functionality and access to third party content,
                  including linking Account Owner data to data maintained by an
                  Enterprise Administrator, or linking the data of two Account
                  Owners, or linking Users to public Websites, directories,
                  servers, networks, systems, information and databases,
                  applications, software, programs, products or services, and
                  the Internet as a whole; Users acknowledge that we are not
                  responsible for such content or services. We may also provide
                  some content to Users as part of the Services. However,
                  Pentugram is not an agent of any transacting party, nor are we
                  a direct party in any such transaction. Any such activities,
                  and any terms associated with such activities, are solely
                  between the User and the applicable third-party. Similarly, we
                  are not responsible for any third party content a User
                  accesses with the Services, and the Account Owner (or
                  Enterprise Administrator on behalf of the Account Owner, where
                  applicable) irrevocably waives any claim against us with
                  respect to such sites and third-party content. Pentugram shall
                  have no liability, obligation or responsibility for any such
                  correspondence, purchase or promotion between Users and any
                  such third-party. Users should make whatever investigation
                  they feel necessary or appropriate before proceeding with any
                  online or offline transaction with any of these third parties.
                  Users are solely responsible for their dealings with any third
                  party related to the Services, including the delivery of and
                  payment for goods and services. Should a User have any
                  problems resulting from their use of any third party services,
                  or should the Account Owner suffer data loss or other losses
                  as a result of problems with any of their other service
                  providers or any third-party services, we will not be
                  responsible.
                </p>
              </li>
              <li>
                <strong>PENTUGRAM PROPRIETARY RIGHTS</strong>
                <p>
                  As between Pentugram and Users, Pentugram or its licensors own
                  and reserve all right, title and interest in and to the
                  Service and all hardware, software and other items used to
                  provide the Service, other than the rights explicitly granted
                  to Users to use the Service in accordance with these Terms. No
                  title to or ownership of any proprietary rights related to the
                  Service is transferred to Users pursuant to these Terms. All
                  rights not explicitly granted to Users are reserved by
                  Pentugram. In the event that a User provides comments,
                  suggestions and recommendations to Pentugram with respect to
                  the Service (including, without limitation, with respect to
                  modifications, enhancements, improvements and other changes to
                  the Service) (collectively, “Feedback”), the User hereby
                  grants to Pentugram a world-wide, royalty free, irrevocable,
                  perpetual license to use and otherwise incorporate any
                  Feedback in connection with the Service.
                </p>
              </li>
              <li>
                <strong>CHANGES TO THESE TERMS</strong>
                <p>
                  We reserve the right to revise these Terms at any time from
                  time to time. We will date and post the most current version
                  of these Terms on the Pentugram website. Any changes to the
                  Terms will be effective upon posting the revised version of
                  these Terms on the Website (or such later effective date as
                  may be indicated at the top of the revised Terms). If in our
                  sole discretion we deem a revision to these Terms to be
                  material, we will notify Users via the Website and/or by email
                  to the email address associated with the Account Owner and/or
                  their Enterprise Administrator. Notice of other changes may be
                  provided via the Website or related Pentugram blogs.
                  Therefore, we encourage Users to check the date of these Terms
                  whenever they visit the Website to see if these Terms have
                  been updated. A Userâ€™s continued access or use of any
                  portion of the Service constitutes their acceptance of such
                  changes. If a User doesn’t agree to the changes, we are not
                  obligated to keep providing the Service.
                </p>
              </li>
              <li>
                <strong>DATA PROTECTION AND PRIVACY</strong>
                <p>
                  In order to operate and provide the Service, we must collect
                  certain information about Users. As part of the Service, we
                  may also automatically store information about a Userâ€™s
                  computer or device, their use of the Service, and Service
                  performance. We may also utilize browser cookies to assist in
                  identifying and working with Users. We use and protect that
                  information as described in the privacy policy located at
                  “Privacy Policy“. Users acknowledge and agree that we may
                  access or disclose information about them, including the
                  content of their communications, in order to: (a) comply with
                  the law or respond to lawful requests or legal process; (b)
                  protect the rights or property of Pentugram or our customers,
                  including the enforcement of our agreements or policies
                  governing their use of the Service; or (c) act on a good faith
                  belief that such access or disclosure is necessary to protect
                  the personal safety of Pentugram employees, customers, or the
                  public.
                </p>
                <p>
                  We retain the right to block or otherwise prevent delivery of
                  any type of file, email or other communication to or from the
                  Service as part of our efforts to protect the Service, protect
                  our customers, or stop a User from breaching these Terms. The
                  technology or other means we use may hinder or interfere with
                  use of the Service.
                </p>
              </li>
              <li>
                <strong>NO WARRANTY</strong>
                <p>
                  PENTUGRAM PROVIDES THE SERVICE “AS IS”, “WITH ALL FAULTS” AND
                  “AS AVAILABLE”. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                  LAW, PENTUGRAM MAKES NO (AND SPECIFICALLY DISCLAIMS ALL)
                  REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                  IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
                  LIMITATION, ANY WARRANTY THAT THE SERVICE WILL BE
                  UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL COMPONENTS, THAT
                  THE CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED,
                  OR ANY IMPLIED WARRANTY OF MERCHANTABILITY, SATISFACTORY
                  QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                  NON-INFRINGEMENT, AND ANY WARRANTY ARISING OUT OF ANY COURSE
                  OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE. SOME
                  JURISDICTIONS DO NOT ALLOW THE FOREGOING EXCLUSIONS. IN SUCH
                  AN EVENT SUCH EXCLUSION WILL NOT APPLY SOLELY TO THE EXTENT
                  PROHIBITED BY APPLICABLE LAW.
                </p>
                <p>
                  ORGANISATION VALUATIONS, INVESTOR OWNERSHIP POSITIONS,
                  ORGANISATION UPDATES, AND ORGANISATION INFORMATION (INCLUDING
                  INFORMATION ADDED BY ENTERPRISE ADMINISTRATORS) ARE NOT
                  WARRANTIED TO BE ACCURATE AND MAY NOT BE RELIED ON FOR TAX
                  FILINGS OR PROSECUTING OR VALIDATING OWNERSHIP POSITIONS. IT
                  IS EACH USERâ€™S RESPONSIBILITY TO INDEPENDENTLY VERIFY THE
                  INFORMATION IN THEIR ACCOUNT BEFORE RELYING ON IT FOR TAX OR
                  OTHER LEGAL PURPOSES. PENTUGRAM EXPRESSLY DISCLAIMS ANY
                  WARRANTY THAT SUCH VALUATIONS, POSITIONS, UPDATES OR
                  INFORMATION IS CORRECT, COMPLETE, ACCURATE OR TIMELY.
                </p>
              </li>
              <li>
                <strong>INDEMNIFICATION</strong>
                <p>
                  To the extent permitted by law, each Account Owner or
                  Enterprise Administrator on behalf of the Account Owner agrees
                  it will defend Pentugram against any cost, loss, damage, or
                  other liability arising from any third party demand or claim
                  that any Content provided by that Account Owner or Enterprise
                  Administrator on behalf of the Account Owner, or their use of
                  the Service, in breach of these Terms: (a) infringes a
                  registered patent, registered trademark, or copyright of a
                  third party, or misappropriates a trade secret (to the extent
                  that such misappropriation is not the result of Pentugram ‘s
                  actions); (b) breaches any agreement the Account Owner or
                  Enterprise Administrator has on behalf of the Account Owner
                  with a third party including, but not limited to, agreements
                  relating to confidentiality or, (c) violates applicable law or
                  these Terms. Pentugram will reasonably notify an Account Owner
                  or Enterprise Administrator on behalf of the Account Owner
                  (where applicable) of any such claim or demand that is subject
                  to their indemnification obligation.
                </p>
              </li>
              <li>
                <strong>LIMITATION OF LIABILITY</strong>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
                  PENTUGRAM, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                  SUPPLIERS OR LICENSORS BE LIABLE FOR (A): ANY INDIRECT,
                  INCIDENTAL, SPECIAL, PUNITIVE, COVER OR CONSEQUENTIAL DAMAGES
                  (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS,
                  REVENUE, GOODWILL, USE OR CONTENT) HOWEVER CAUSED, UNDER ANY
                  THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, CONTRACT,
                  TORT, WARRANTY, NEGLIGENCE OR OTHERWISE, EVEN IF PENTUGRAM HAS
                  BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES. TO THE
                  MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE
                  LIABILITY OF SERAF AND ITS AFFILIATES, OFFICERS, EMPLOYEES,
                  AGENTS, SUPPLIERS OR LICENSORS, RELATING TO THE SERVICES WILL
                  BE LIMITED TO THE GREATER OF AN AMOUNT EQUAL TO TWELVE MONTHS
                  OF AN ACCOUNT OWNERâ€™S OR ENTERPRISE ADMINISTRATORâ€™S
                  SERVICE FEE FOR THE SERVICE OR FIFTY DOLLARS ($50.00). THE
                  LIMITATIONS AND EXCLUSIONS ALSO APPLY IF THIS REMEDY DOES NOT
                  FULLY COMPENSATE A USER FOR ANY LOSSES OR FAILS OF ITS
                  ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE
                  LIMITATION OF INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES. IN
                  SUCH AN EVENT THIS LIMITATION WILL NOT APPLY TO A SPECIFIC
                  ACCOUNT OWNER OR ENTERPRISE ADMINISTRATOR TO THE EXTENT
                  PROHIBITED BY LAW.
                </p>
              </li>
              <li>
                <strong>GOVERNING LAW</strong>
                <p>
                  Except as expressly provided otherwise, these Terms will be
                  governed by, and be construed under, the laws of the United
                  Arab Emirates, without regard to choice of law principles.
                  Users consent to jurisdiction and venue exclusively in the
                  courts in United Arab Emirates. Users further agree that
                  Pentugram retains the right to seek injunctive or other
                  equitable relief in a court of competent jurisdiction to
                  prevent the actual or threatened infringement,
                  misappropriation or violation of a party’s copyrights,
                  trademarks, trade secrets, patents, or other intellectual
                  property rights. You agree that regardless of any statute or
                  law to the contrary, any claim or cause of action arising out
                  of these Terms or related to use of the Service must be filed
                  by you within six (6) months after such claim or cause of
                  action arose or be forever barred.
                </p>
              </li>
              <li>
                <strong>INTELLECTUAL PROPERTY NOTICES</strong>
                <p>
                  All contents of the Website and Services including but not
                  limited to design, text, software, technical drawings,
                  configurations, graphics, other files, and their selection and
                  arrangement are Pentugramâ€™s , and/or the proprietary
                  property of its suppliers, affiliates, or licensors. All
                  Rights Reserved.
                </p>
                <p>
                  Pentugram and the Pentugram logo are including without
                  limitation, either trademarks, service marks or registered
                  trademarks of Pentugram Holding Ltd, and may not be copied,
                  imitated, or used, in whole or in part, without Pentugramâ€™s
                  prior written permission or that of our suppliers or
                  licensors. Other product and organisation names may be trade
                  or service marks of their respective owners.
                </p>
                <p>
                  Pentugram may have patents, patent applications, trademarks,
                  copyrights, or other intellectual property rights covering
                  subject matter that is part of the Service. Unless we have
                  granted the Account Owner and express written license to our
                  intellectual property, our providing the Account Owner with
                  the Service does not give that Account Owner any license to
                  our intellectual property. Any rights not expressly granted
                  herein are reserved.
                </p>
              </li>
              <li>
                <strong>NOTICES</strong>
                <p>
                  We may send Users, in electronic form, information about the
                  Service, additional information, and information the law
                  requires us to provide. We may provide required information to
                  Users by email at the address the Account Owner or Enterprise
                  Administrator specified on behalf of the Account Owner when
                  they signed up for the Service (or by subsequent written
                  notice of update to us) or by access to a website that we
                  identify. Notices emailed to an Account Ownerâ€™s or
                  Enterprise Administratorâ€™s supplied address of record will
                  be deemed given and received when the email is sent. If an
                  Account owner doesn’t consent to receive notices
                  electronically, they must stop using the Service. Account
                  Owners may provide legal notice to us via email to &nbsp;
                  <a href="mailto:support@pentugram.com">
                     support@pentugram.com
                  </a>
                  , with a duplicate copy sent via registered mail, return
                  receipt requested, to the following address: insert postal
                  address. Any such notice, in either case, must specifically
                  reference that it is a notice given under these Terms.
                </p>
              </li>
              <li>
                <strong>MISCELLANEOUS</strong>
                <ol>
                  <li>
                    <strong>Severability; Entire Agreement</strong>
                    <p>
                      These Terms apply to the maximum extent permitted by
                      relevant law. If a court holds that we cannot enforce a
                      part of these Terms as written, the parties will replace
                      those terms with similar terms to the extent enforceable
                      under the relevant law, but the rest of these Terms will
                      remain in effect. This is the entire contract between the
                      Account Owner or the Enterprise Administrator on behalf of
                      the Account Owner and us regarding the Service. It
                      supersedes any prior contract or oral or written
                      statements regarding an Account Ownerâ€™s use of the
                      Service.
                    </p>
                  </li>
                  <li>
                    <strong>Assignment and transfer</strong>
                    <p>
                      We may assign, transfer, or otherwise dispose our rights
                      and obligations under this contract, in whole or in part,
                      at any time without notice. The Account Owner or
                      Enterprise Administrator may not assign this contract or
                      transfer any rights to use the Service without
                      Pentugramâ€™s express prior written consent.
                    </p>
                  </li>
                  <li>
                    <strong>
                      Independent Contractors; No third-party beneficiaries
                    </strong>
                    <p>
                      Pentugram and the Account Owner are not legal partners or
                      agents; instead, our relationship is that of independent
                      contractors. This contract is solely for Users and our
                      benefit. It is not for the benefit of any other person,
                      except for permitted successors.
                    </p>
                  </li>
                  <li>
                    <strong>Claims</strong>
                    <p>
                      Claims must be filed within six (6) months. The Account
                      Owner must bring any claim related to these Terms or the
                      Service within one year of the date that the Account Owner
                      could first bring the claim, unless their local law
                      requires a longer time to file claims. If it isn’t filed
                      in time, the claim is permanently barred.
                    </p>
                  </li>
                  <li>
                    <strong>Waiver</strong>
                    <p>
                      The failure of either party to insist upon or enforce
                      strict performance of any of the provisions of these Terms
                      or to exercise any rights or remedies under these Terms
                      will not be construed as a waiver or relinquishment to any
                      extent of such party’s right to assert or rely upon any
                      such provision, right or remedy in that or any other
                      instance; rather, the same will remain in full force and
                      effect.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </main>
      <FooterSection />
    </>
  );
}

export default TermsPage;
