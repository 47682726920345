import themeConfig from "../../../lib/theme/tokens";

function PaperPlaneIcon() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333496 24C0.333496 10.7452 11.0787 0 24.3335 0C37.5883 0 48.3335 10.7452 48.3335 24C48.3335 37.2548 37.5883 48 24.3335 48C11.0787 48 0.333496 37.2548 0.333496 24Z"
        fill="#F5F8FD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4585 17.25C15.8372 17.25 15.3335 17.7537 15.3335 18.375V29.625C15.3335 30.2463 15.8372 30.75 16.4585 30.75H32.2085C32.8298 30.75 33.3335 30.2463 33.3335 29.625V18.375C33.3335 17.7537 32.8298 17.25 32.2085 17.25H16.4585ZM13.8335 18.375C13.8335 16.9253 15.0087 15.75 16.4585 15.75H32.2085C33.6582 15.75 34.8335 16.9253 34.8335 18.375V29.625C34.8335 31.0747 33.6582 32.25 32.2085 32.25H16.4585C15.0087 32.25 13.8335 31.0747 13.8335 29.625V18.375Z"
        fill="#3070CC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9915 19.0395C17.2458 18.7126 17.717 18.6537 18.044 18.908L24.3335 23.7999L30.623 18.908C30.95 18.6537 31.4212 18.7126 31.6755 19.0395C31.9298 19.3665 31.8709 19.8377 31.544 20.092L24.794 25.342C24.5231 25.5527 24.1439 25.5527 23.873 25.342L17.123 20.092C16.7961 19.8377 16.7372 19.3665 16.9915 19.0395Z"
        fill="#3070CC"
      />
    </svg>
  );
}

export default PaperPlaneIcon;
