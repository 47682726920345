import React from "react";
import Slider from "react-slick";


function SectionSlider({ children, slidesToShow, isMobile = false }: { children: React.ReactNode, slidesToShow: number, isMobile?: boolean}) {


  const settings = {
    dots: isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    
  };

  return (
    <Slider className="slider-container" {...settings}>
      {children}
    </Slider>
  );
}

export default SectionSlider;
