import { theme } from "antd";
import colors from "./colors";
import fonts from "./fonts";

const themeValues = theme.getDesignToken(); 

const themeConfig = {
    ...themeValues,
    colors,
    fonts
};

export default themeConfig;