import styled from "styled-components";
import { Layout } from 'antd';
import themeToken from "../tokens";

const { Header } = Layout;



export const StyledHeader = styled(Header)`
    background-color: ${themeToken.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 80px;
    gap: 32px;
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${themeToken.colors.natural[100]};
`

export const StyledNavbarButtonGroup = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    margin-right: 40px;
`;

export const StyledNavbarButtonGroupRight = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    
`;

export const StyledNavbarButton = styled.button<{$isActive: boolean}>`
    background-color: ${themeToken.colors.white};
    border: none;
    color: ${({$isActive}) => $isActive ? themeToken.colors.primary[500] : themeToken.colors.natural[500]};
    padding: 12px;
    font-size: ${themeToken.fonts.fontSizeNormal};
    border-radius: 4px;
    font-weight: ${themeToken.fonts.fontWeightBold};
    cursor: pointer;
    &:hover {
        color: ${themeToken.colors.primary[500]};
    }
`;

export const StyledBookADemoButtonNavbar = styled.button`
    border: 1px solid ${themeToken.colors.natural[100]};
    background-color: ${themeToken.colors.white};
    border-radius: 6px;
    padding: 9px 14px 9px 14px;
    cursor: pointer;
    &:hover {
        background-color: ${themeToken.colors.natural[25]};
    }
`

export const StyledLoginButtonNavbar = styled.button`
    border: 1px solid ${themeToken.colors.primary[500]};
    background-color: ${themeToken.colors.primary[500]};
    border-radius: 6px;
    padding: 9px 14px 9px 14px;
    color: ${themeToken.colors.white};
    font-weight: ${themeToken.fonts.fontWeightMD};
    cursor: pointer;
    &:hover {
        background-color: ${themeToken.colors.primary[600]};
        border: 1px solid ${themeToken.colors.primary[600]};
    }

    &:disabled {
        background-color: ${themeToken.colors.natural[100]};
        border: 1px solid ${themeToken.colors.natural[100]};
        color: ${themeToken.colors.natural[500]};
        cursor: not-allowed;
    }
    
`