import { useEffect, useRef, useState } from "react";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import FooterSection from "../../components/Footer/Footer";
import HeroSection from "../../components/HeroSection/HeroSection";
import Navbar from "../../components/Navbar/Navbar";
import Newsbar from "../../components/Newsbar/Newsbar";
import PricingSection from "../../components/PricingSection/PricingSection";
import SecuritySection from "../../components/SecuritySection/SecuritySection";
import StartTrialSection from "../../components/StartTrialSection/StartTrialSection";
import ToolsSection from "../../components/ToolsSection/ToolsSection";
import TrustedBySection from "../../components/TrustedBySection/TrustedBySection";
import WhyPentugramSection from "../../components/WhyPentugramSection/WhyPentugramSection";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import React from "react";
import { useLocation, useMatch } from "react-router-dom";
import { Divider } from "antd";

export default function HomePage() {
  const [isScrolledTo, setIsScrolledTo] = useState<string>("home");
  const HeroRef = React.createRef<any>();
  const FeaturesRef = React.createRef<any>();
  const WhyPentugramRef = React.createRef<any>();
  const TrustedByRef = React.createRef<any>();
  const PricingRef = React.createRef<any>();

  const [windowDimensions] = useState(getWindowDimensions());
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      if (
        HeroRef.current &&
        FeaturesRef.current &&
        WhyPentugramRef.current &&
        TrustedByRef.current &&
        PricingRef.current
      ) {
        const heroPosition = HeroRef.current.getBoundingClientRect().top;
        const featuresPosition =
          FeaturesRef.current.getBoundingClientRect().top;
        const whyPentugramPosition =
          WhyPentugramRef.current.getBoundingClientRect().top;
        const trustedByPosition =
          TrustedByRef.current.getBoundingClientRect().top;
        const pricingPosition = PricingRef.current.getBoundingClientRect().top;

        const windowHeight = window.innerHeight;
        // Change this threshold as needed
        const threshold = 600; // in pixels
        if (
          heroPosition < windowHeight - threshold &&
          featuresPosition > windowHeight - threshold
        ) {
          setIsScrolledTo("home");
        } else if (
          featuresPosition < windowHeight - threshold &&
          whyPentugramPosition > windowHeight - threshold
        ) {
          setIsScrolledTo("features");
        } else if (
          whyPentugramPosition < windowHeight - threshold &&
          trustedByPosition > windowHeight - threshold
        ) {
          setIsScrolledTo("why-pentugram");
        } else if (
          trustedByPosition < windowHeight - threshold &&
          pricingPosition > windowHeight - threshold
        ) {
          setIsScrolledTo("our-clients");
        } else if (pricingPosition < windowHeight - threshold) {
          setIsScrolledTo("pricing");
        } else {
          setIsScrolledTo("");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [HeroRef, FeaturesRef, WhyPentugramRef, TrustedByRef, PricingRef]);


  return (
    <>
      <Newsbar />
      <Navbar activeSection={isScrolledTo} />
      <main >
        <HeroSection ref={HeroRef} />
        <Divider style={{ minWidth: "90%", width: "90%" }}></Divider>
        <FeaturesSection ref={FeaturesRef} />
        <WhyPentugramSection ref={WhyPentugramRef} />
        <SecuritySection />
        <ToolsSection />
        <TrustedBySection ref={TrustedByRef} />
        <PricingSection ref={PricingRef} />
        <StartTrialSection />
      </main>
      <footer>
        <FooterSection />
      </footer>
    </>
  );
}
