import { Tabs } from "antd";
import DealFeature from "./components/DealFeature/DealFeature";
import PortfolioFeature from "./components/PortfolioFeature/PortfolioFeature";
import ReportFeature from "./components/ReportFeature/ReportFeature";
import styled from "styled-components";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { forwardRef, useState } from "react";
import { Section } from "../../shared/components/Section";

const StyledTabs = styled(Tabs)<{ $isMobile: boolean }>`
  &:where(.css-dev-only-do-not-override-1f6kyex).ant-tabs
    > .ant-tabs-nav
    .ant-tabs-nav-list,
  :where(.css-dev-only-do-not-override-1f6kyex).ant-tabs
    > div
    > .ant-tabs-nav
    .ant-tabs-nav-list {
    border-bottom: 1px solid #f0f0f0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  :where(.css-dev-only-do-not-override-1f6kyex).ant-tabs .ant-tabs-tab {
    justify-content: center;
  }
  &:where(.css-dev-only-do-not-override-1f6kyex).ant-tabs-top
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-1f6kyex).ant-tabs-bottom
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-1f6kyex).ant-tabs-top
    > div
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-1f6kyex).ant-tabs-bottom
    > div
    > .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-nav-list{
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    padding: 20px 16px 24px 16px;
  }
`;

const StyledMobileTabsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;

  &.active {
    border-bottom: 1px solid #1890ff;
  }
`;

const StyledMobileTabs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MobileTabs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #f0f0f0;
  padding: 16px 0;
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  list-style: none;
`;

const FeaturesSection = forwardRef((props: any, ref: any) => {
  const [windowDimensions] = useState(getWindowDimensions());
  const [activeTab, setActiveTab] = useState("1" as string);

  const tabItems = [
    {
      key: "1",
      label: "Manage Your Deal Flow",
      children: <DealFeature />,
    },
    {
      key: "2",
      label: "Manage Your Portfolio",
      children: <PortfolioFeature />,
    },
    {
      key: "3",
      label: "Report Easily",
      children: <ReportFeature />,
    },
  ];

  return windowDimensions.width > 768 ? (
    <div id="features" style={{paddingTop: "64px"}} ref={ref}>
      <h2
        style={{
          padding: "0px 32px 0px 32px",
          fontWeight: "700",
          marginTop: "0",
          fontSize: "36px",
        }}
      >
        What You’ll Get
      </h2>
      <p style={{ fontWeight: 700, fontSize: "20px" }}>
        Manage your deal flow, portfolio & report to your partners easily.
      </p>
      <div style={{ padding: "64px 0" }}>
        <StyledTabs
          $isMobile={windowDimensions.width < 768}
          tabBarStyle={{ fontWeight: "bold", marginBottom: "64px" }}
          centered
          //   renderTabBar={renderTabBar}
          style={{ width: "80%", margin: "auto" }}
          items={tabItems}
        />
      </div>
    </div>
  ) : (
    <div ref={ref} id="features" style={{ padding: "64px 32px 32px 32px" }}>
      <h2
        style={{
          fontWeight: "700",
          fontSize: "36px",
        }}
      >
        What You’ll Get
      </h2>
      <p style={{ fontWeight: 700, fontSize: "20px" }}>
        Manage your deal flow, portfolio & report to your partners easily.
      </p>
      <div>
        {tabItems.map((item) => (
          <div
            key={item.key}
            style={{
              display: activeTab === item.key ? "block" : "none",
            }}
          >
            {item.children}
          </div>
        ))}
      </div>
      <StyledMobileTabsContainer>
        <StyledMobileTabs>
          {tabItems.map((item) => (
            <MobileTabs
              key={item.key}
              style={{
                borderBottom: activeTab === item.key ? "4px solid #1890ff" : "",
              }}
              onClick={() => setActiveTab(item.key)}
            >
              {item.label}
            </MobileTabs>
          ))}
        </StyledMobileTabs>
      </StyledMobileTabsContainer>
    </div>
  );
});

export default FeaturesSection;
