import { Section, StyledSectionHeader, StyledSectionSubHeader } from "../../shared/components/Section";
import SecuritySectionCard from "./components/SecuritySectionCard";
import SecurityCloud from "../../assets/images/security-cloud.svg";
import SecurityLock from "../../assets/images/security-lock.png";
import SecurityFolder from "../../assets/images/security-folder.svg";
import SecurityServer from "../../assets/images/security-server.svg";
import styled from "styled-components";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { useState } from "react";

//use grid to make the cards have the same height
const StyledSecurityContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 32px;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    grid-gap: 32px;
    align-items: center;
`;

function SecuritySection({ref}: any) {

    const [windowDimensions] = useState(getWindowDimensions());

    return (
        <Section ref={ref} id="security-section" $padding={windowDimensions.width > 768 ? "96px 112px 96px 112px" :  "96px 50px 96px 50px"}>
            <StyledSectionHeader>Secure Your Data</StyledSectionHeader>
            <StyledSectionSubHeader>All your data safe in one place, with access to one IP and regular backups.</StyledSectionSubHeader>
            <StyledSecurityContainer>
                <SecuritySectionCard icon={SecurityCloud} title="Power Of The Cloud" description="Onboard your Potential Investments, filter and create a customizable pipeline without effort." />
                <SecuritySectionCard icon={SecurityLock} title="Encrypted connection" description="Consolidate all your fund's spreadsheets including numbers and value of deals." />
                <SecuritySectionCard icon={SecurityFolder} title="Data afe" description="Build your customized reports, merge between the Ritch editors with having all the data in its back-office." />
                <SecuritySectionCard icon={SecurityServer} title="Secure framework" description="All your data safe in one place, with access to one IP and regular backups." />
            </StyledSecurityContainer>
        </Section>
    );
}

export default SecuritySection;