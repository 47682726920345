import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styled from "styled-components";
import themeConfig from "../../../lib/theme/tokens";

const StyledDetails = styled.div<{$isOpen: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  border: ${(({$isOpen}) => $isOpen ? `1px solid ${themeConfig.colors.natural[50]}` : "none")};
  border-radius: 16px;
  box-shadow: ${(({$isOpen}) => $isOpen ? "0px 1px 2px 0px #0409210d" : "none")};
  text-align: left;
  cursor: pointer;
  background-color: ${({$isOpen}) => $isOpen ? "white" : "transparent"};
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: #101828;
    }
`;

const StyledHeroDescription = styled.div<{$isOpen: boolean}>`
    display: ${({$isOpen}) => $isOpen ? "block" : "none"};
    transition: all 0.8s ease-in-out;
    font-size: 16px;
    font-weight: 400;
    color: ${themeConfig.colors.textSecondary};
`;

function FaqItem({ question, answer }: { question: string; answer: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledDetails $isOpen={isOpen}>
      <StyledTitleContainer onClick={() => setIsOpen(!isOpen)}>
        <h3>{question}</h3>
        {isOpen ? (
          <MinusCircleOutlined style={{ fontSize: "20px", color: themeConfig.colors.natural[400] }}/>
        ) : (
          <PlusCircleOutlined style={{ fontSize: "20px", color: themeConfig.colors.natural[400]  }} />
        )}
      </StyledTitleContainer>
      <StyledHeroDescription $isOpen={isOpen}>{answer}</StyledHeroDescription>
    </StyledDetails>
  );
}

export default FaqItem;
