import { create } from "zustand";

interface firwork {
    isFireworkOn: boolean;
}

export const useFireworkStore = create((set) => ({
    firework: {
        isFireworkOn: true,
    },
    setIsFireworkOn: (firework : firwork) => set({ firework }),   
}));