import themeConfig from "../../../lib/theme/tokens";

function LocationIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#F5F8FD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5 20.6719C16.5 16.6788 19.8948 13.5 24 13.5C28.1052 13.5 31.5 16.6788 31.5 20.6719C31.5 22.923 30.2829 25.7082 28.9495 28.1155C27.5963 30.559 26.0377 32.7603 25.2019 33.8891C25.064 34.0776 24.8837 34.231 24.6755 34.337C24.4663 34.4435 24.2348 34.499 24 34.499C23.7652 34.499 23.5337 34.4435 23.3245 34.337C23.1163 34.231 22.9359 34.0776 22.798 33.889C21.9622 32.7598 20.4037 30.5577 19.0504 28.1139C17.7172 25.7064 16.5 22.9214 16.5 20.6719ZM24 15C20.6521 15 18 17.5766 18 20.6719C18 22.5004 19.0328 24.9859 20.3627 27.3872C21.6689 29.746 23.1818 31.886 24 32.9917C24.8181 31.8865 26.3311 29.7473 27.6374 27.3888C28.9671 24.9878 30 22.5021 30 20.6719C30 17.5766 27.3479 15 24 15Z"
        fill="#3070CC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 19.5C23.1716 19.5 22.5 20.1716 22.5 21C22.5 21.8284 23.1716 22.5 24 22.5C24.8284 22.5 25.5 21.8284 25.5 21C25.5 20.1716 24.8284 19.5 24 19.5ZM21 21C21 19.3431 22.3431 18 24 18C25.6569 18 27 19.3431 27 21C27 22.6569 25.6569 24 24 24C22.3431 24 21 22.6569 21 21Z"
        fill="#3070CC"
      />
    </svg>
  );
}

export default LocationIcon;
