import styled from "styled-components";
import FeatureDealImage from "../../../../assets/images/Mockup-wrap-1.png";
import { Image, theme } from "antd";
import { CheckOutlined, LineChartOutlined } from "@ant-design/icons";
import themeConfig from "../../../../lib/theme/tokens";
import { StyledBookADemoButtonNavbar } from "../../../../lib/theme/components/Layout";
import { get } from "http";
import { getWindowDimensions } from "../../../../utils/getWindowDimesnsions";
import { useState } from "react";
import FeatureChartOutline from "../../../../assets/images/bar-chart.png";
import {
  StyledBookADemoFeature,
  StyledCheckOutlined,
  StyledContentContainer,
  StyledDealFeatureContainer,
  StyledFeatureTitle,
  StyledFeautreDescription,
  StyledLineChartOutlined,
  StyledUnorderListItem,
  StyledUnorderedList,
  StyledUnorderedListItemContainer,
} from "../../../../lib/theme/components/Features";
import { useNavigate } from "react-router-dom";

function DealFeature() {
  const navigate = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  return (
    <StyledDealFeatureContainer
      style={{ flexDirection: windowDimensions.width < 768 ? "column" : "row" }}
    >
      <StyledContentContainer
        style={windowDimensions.width > 768 ? { width: "50%" } : {}}
      >
        <img src={FeatureChartOutline} alt="Feature Chart Outline" />
        <StyledFeatureTitle>Manage Your Deal Flow</StyledFeatureTitle>
        <StyledFeautreDescription>
          Onboard your Potential Investments, filter and create a customizable
          pipeline without effort.
        </StyledFeautreDescription>
        <StyledUnorderedList>
          <StyledUnorderedListItemContainer>
            <StyledCheckOutlined
              style={{ color: themeConfig.colors.primary[500] }}
            />{" "}
            <StyledUnorderListItem>
              View a visual pipeline breakdown
            </StyledUnorderListItem>
          </StyledUnorderedListItemContainer>
          <StyledUnorderedListItemContainer>
            <StyledCheckOutlined
              style={{ color: themeConfig.colors.primary[500] }}
            />{" "}
            <StyledUnorderListItem>
              Pipelines based on your process stages
            </StyledUnorderListItem>
          </StyledUnorderedListItemContainer>
          <StyledUnorderedListItemContainer>
            <StyledCheckOutlined
              style={{ color: themeConfig.colors.primary[500] }}
            />{" "}
            <StyledUnorderListItem>
              Different process for each pipeline
            </StyledUnorderListItem>
          </StyledUnorderedListItemContainer>
          <StyledUnorderedListItemContainer>
            <StyledCheckOutlined
              style={{ color: themeConfig.colors.primary[500] }}
            />{" "}
            <StyledUnorderListItem>
              Customizable data inputs for your deals
            </StyledUnorderListItem>
          </StyledUnorderedListItemContainer>
        </StyledUnorderedList>
        <StyledBookADemoFeature
          onClick={() => navigate("/book-a-demo")}
          style={windowDimensions.width < 768 ? { marginBottom: "32px" } : {}}
        >
          Book a demo
        </StyledBookADemoFeature>
      </StyledContentContainer>
      <div style={windowDimensions.width > 768 ? { width: "50%" } : {}}>
        <Image
          preview={false}
          style={{
            position: "relative",
          }}
          src={FeatureDealImage}
        />
      </div>
    </StyledDealFeatureContainer>
  );
}

export default DealFeature;
