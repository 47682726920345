import { useState } from "react";
import { ReactComponent as NavbarIcon } from "../../assets/images/navbar-logo.svg";
import {
  StyledBookADemoButtonNavbar,
  StyledHeader,
  StyledLoginButtonNavbar,
  StyledNavbarButton,
  StyledNavbarButtonGroup,
  StyledNavbarButtonGroupRight,
} from "../../lib/theme/components/Layout";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { Button, Menu } from "antd";
import {
  CloseOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useMatch, useNavigate } from "react-router-dom";
import themeConfig from "../../lib/theme/tokens";
import { useMixpanel } from "react-mixpanel-browser";

const MobileNavbarContainer = styled.nav<{ $height: string; $width: string }>`
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  transition: all 0.1s;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  z-index: 1;
`;

const MobileNavbarItem = styled.div<{ $isActive: boolean }>`
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  color: ${({ $isActive }) =>
    $isActive ? themeConfig.colors.primary[500] : "#000"};
  text-align: center;
`;

const MobileHeader = styled.header`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${themeConfig.colors.natural[100]};
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 2;

`;

function Navbar({ activeSection = "" }: { activeSection?: string }) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const mixpanel = useMixpanel();

  const menuItems = [
    {
      key: "home",
      label: "Home",
    },
    {
      key: "features",
      label: "Features",
    },
    {
      key: "why-pentugram",
      label: "Why Pentugram",
    },
    {
      key: "our-clients",
      label: "Our Clients",
    },
    {
      key: "pricing",
      label: "Pricing",
    },
    {
      key: "contact",
      label: "Contact",
    },
  ];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const navigateToView = async (key: string) => {
    if (key !== "contact") {
      await navigate(`/#${key}`);
      document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
    } else {
      await navigate("/contact");
    }
  };

  const desktopNavbar = () => {
    return (
      <StyledHeader>
        <NavbarIcon
          onClick={() => navigateToView("home")}
          style={{ cursor: "pointer" }}
        />
        <StyledNavbarButtonGroup>
          {menuItems.map((item, index) => {
            return (
              <StyledNavbarButton
                onClick={(e) => {mixpanel.track(`${item.label} - LP - navbar`, {}); navigateToView(item.key)}}
                key={index}
                $isActive={activeSection === item.key}
              >
                {item.label}
              </StyledNavbarButton>
            );
          })}
        </StyledNavbarButtonGroup>
        <StyledNavbarButtonGroupRight>
          <StyledBookADemoButtonNavbar onClick={() => {mixpanel.track("Book a demo - LP - navbar", {}); navigate("/book-a-demo")}}>
            Book a demo
          </StyledBookADemoButtonNavbar>
          <StyledLoginButtonNavbar
            onClick={() => {mixpanel.track("Login - LP - navbar", {}); window.location.href = "https://app.pentugram.com"}}
          >
            Login
          </StyledLoginButtonNavbar>
        </StyledNavbarButtonGroupRight>
      </StyledHeader>
    );
  };

  const mobileNavbar = () => {
    return (
      <>
        <MobileHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
              height: "80px",
            }}
          >
            <NavbarIcon />
            <Button
              type="primary"
              onClick={toggleCollapsed}
              style={{ marginBottom: 16 }}
            >
              {collapsed ? <CloseOutlined /> : <MenuOutlined />}
            </Button>
          </div>
          <MobileNavbarContainer
            $height={collapsed ? "auto" : "0"}
            $width={`${windowDimensions.width}px`}
          >
            {menuItems.map((item, index) => {
              return (
                <MobileNavbarItem
                  onClick={(e) => {mixpanel.track(`${item.label} - LP - navbar`, {}); navigateToView(item.key)}}
                  $isActive={activeSection === item.key}
                  key={index}
                >
                  {item.label}
                </MobileNavbarItem>
              );
            })}
          </MobileNavbarContainer>
        </MobileHeader>
      </>
    );
  };

  return windowDimensions.width > 768 ? desktopNavbar() : mobileNavbar();
}

export default Navbar;
