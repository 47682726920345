import { forwardRef, useState } from "react";
import {
  Section,
  StyledSectionHeader,
  StyledSectionSubHeader,
} from "../../shared/components/Section";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { Segmented } from "antd";
import themeConfig from "../../lib/theme/tokens";
import PackageCard from "./components/PackageCard";
import styled from "styled-components";
import { useSpring } from "@react-spring/web";

const StyledPackageContainer = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  align-items: center;
`;

const PricingSection = forwardRef((props: any, ref: any) => {
  const [windowDimensions] = useState(getWindowDimensions());
  const [sliverPrice, setSilverPrice] = useState("$29.99");
  const [goldPrice, setGoldPrice] = useState("$49.99");
  const [isMonthly, setIsMonthly] = useState(true);
  const [springs, api] = useSpring(() => ({
    transform: "scale(1)",
    config: { mass: 1, tension: 170, friction: 26 },
  }));

  const onChange = (value: string) => {
    api.start({
      transform: "scale(0)",
      config: { mass: 1, tension: 170, friction: 26 },
    });
    setSilverPrice(value === "Monthly" ? "$29.99" : "$23.99");
    setGoldPrice(value === "Monthly" ? "$49.99" : "$39.99");
    setIsMonthly(value === "Monthly");
    setTimeout(() => {
      api.start({
        transform: "scale(1)",
        config: { mass: 1, tension: 170, friction: 26 },
      });
    }, 300);
  };

  return (
    <Section
      id="pricing"
      ref={ref}
      $padding={
        windowDimensions.width > 768
          ? "96px 112px 96px 112px"
          : "96px 50px 96px 50px"
      }
      style={{ gap: "32px" }}
    >
      <div>
        <StyledSectionHeader>Plans that fit your scale</StyledSectionHeader>
        <StyledSectionSubHeader>
          Simple, transparent pricing that grows with you. Try any plan free for
          15 days.
        </StyledSectionSubHeader>
      </div>
      <div>
        <Segmented<string>
          options={["Monthly", "Annually"]}
          size="large"
          onChange={onChange}
        />
        {isMonthly ? null : (
          <p
            style={{
              fontWeight: "500",
              fontSize: "18px",
              marginBottom: "0",
              color: themeConfig.colors.primary[500],
            }}
          >
            (save 20%)
          </p>
        )}
      </div>
      <StyledPackageContainer
        style={{
          flexDirection: windowDimensions.width > 768 ? "row" : "column",
        }}
      >
        <PackageCard
          features={[
            <span>Pipeline Management</span>,
            <span>Portfolio Manager up to 20 Companies</span>,
            <span>Reporting Tool Up to 6 reports</span>,
            <span>Task Management</span>,
            <span>Contacts Management</span>,
            <span>Data Migration</span>,
          ]}
          isPopular={true}
          price={sliverPrice}
          title="Silver"
          springs={springs}
          pricePerioud="Monthly"
        />
        <PackageCard
          features={[
            <span>Pipeline Management</span>,
            <span>
              <strong style={{ color: themeConfig.colors.primary[500] }}>
                Unlimited
              </strong>{" "}
              Portfolio Manager
            </span>,
            <span>
              <strong style={{ color: themeConfig.colors.primary[500] }}>
                Unlimited
              </strong>{" "}
              Reporting Tool
            </span>,
            <span>Task Management</span>,
            <span>Contacts Management</span>,
            <span>Data Migration</span>,
          ]}
          isPopular={false}
          price={goldPrice}
          springs={springs}
          title="Gold"
          pricePerioud="Monthly"
        />
      </StyledPackageContainer>
    </Section>
  );
});

export default PricingSection;
