import { useState } from "react";
import { getWindowDimensions } from "../../../../utils/getWindowDimesnsions";
import { StyledBookADemoFeature, StyledCheckOutlined, StyledContentContainer, StyledDealFeatureContainer, StyledFeatureTitle, StyledFeautreDescription, StyledLineChartOutlined, StyledUnorderListItem, StyledUnorderedList, StyledUnorderedListItemContainer } from "../../../../lib/theme/components/Features";
import themeConfig from "../../../../lib/theme/tokens";
import { Image } from "antd";
import FeaturePieChart from "../../../../assets/images/pie-chart.png"; 
import { useNavigate } from "react-router-dom";
import FeaturePortifolioImage from "../../../../assets/images/Mockup-wrap-2.png";

function PortfolioFeature() {
    const navigate = useNavigate();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    return (
        <StyledDealFeatureContainer style={{flexDirection: windowDimensions.width < 768 ? "column": "row"}}>
            <StyledContentContainer style={windowDimensions.width > 768 ?  {width: "50%" } : {}}>
                <img src={FeaturePieChart}  alt="feature pie chart"/>
                <StyledFeatureTitle>Manage Your Portfolio</StyledFeatureTitle>
                <StyledFeautreDescription>Consolidate all your fund's spreadsheets including numbers and value of deals, recent investment activities, performance, and reporting using a compact dashboard.</StyledFeautreDescription>
                <StyledUnorderedList>
                    <StyledUnorderedListItemContainer><StyledCheckOutlined style={{color: themeConfig.colors.primary[500]}} /> <StyledUnorderListItem>Overview the diversification of your investments</StyledUnorderListItem></StyledUnorderedListItemContainer>
                    <StyledUnorderedListItemContainer><StyledCheckOutlined style={{color: themeConfig.colors.primary[500]}} /> <StyledUnorderListItem>Filter your Portfolio; by fund name, asset class, country, etc..</StyledUnorderListItem></StyledUnorderedListItemContainer>
                    <StyledUnorderedListItemContainer><StyledCheckOutlined style={{color: themeConfig.colors.primary[500]}} /> <StyledUnorderListItem>Customize your data inputs for your deals under portfolio</StyledUnorderListItem></StyledUnorderedListItemContainer>
                    <StyledUnorderedListItemContainer><StyledCheckOutlined style={{color: themeConfig.colors.primary[500]}} /> <StyledUnorderListItem>Collect & monitor KPIs of your portfolio companies​</StyledUnorderListItem></StyledUnorderedListItemContainer>
                </StyledUnorderedList>
                <StyledBookADemoFeature onClick={() => navigate("/book-a-demo")} style={windowDimensions.width < 768 ? { marginBottom: "32px" } : {}}>Book a demo</StyledBookADemoFeature>
            </StyledContentContainer>
            <div style={windowDimensions.width > 768 ?  {width: "50%" } : {}}>
                <Image preview={false} src={FeaturePortifolioImage}/>
            </div>
        </StyledDealFeatureContainer>
    );
}

export default PortfolioFeature;
