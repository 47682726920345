import styled from "styled-components";
import FooterSection from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Newsbar from "../../components/Newsbar/Newsbar";
import themeConfig from "../../lib/theme/tokens";
import { StyledEclipse } from "../../shared/components/Eclipse";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


const StyledIfreme = styled.iframe`
  width: 100%;
  height: 800px;
  border: 0;

`;

function BookADemoPage() {

  const [windowDimensions] = useState(getWindowDimensions());
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Newsbar />
      <Navbar />

      <main
        style={{
          background:
            "linear-gradient(180deg, #F6F9FF 0%, rgba(255, 255, 255, 0) 100%)",
          zIndex: -2,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: windowDimensions.width > 768 ? "0px 32px" : "80px 32px 0px 32px",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: windowDimensions.width > 768 ? "60px" : "36px",
                marginBottom: "0px",
                fontWeight: 600,
                color: themeConfig.colors.natural[800],
              }}
            >
              Book a demo
            </h1>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                color: themeConfig.colors.secondary,
                width: windowDimensions.width > 768 ? "60%" : "100%",
                textAlign: "center",
              }}
            >
              Managing an investment portfolio can be stressful, we understand
              the urgency of having accurate and comprehensible data whenever it
              is needed
            </p>
          </div>
          <div style={{ position: "relative", backgroundColor: "transparent" }}>
            <div >
              <StyledIfreme
                title="Book a demo"
                style={{ backgroundColor: "transparent"}}
                allowTransparency={true}
                src="https://calendly.com/m-elias/pentugram-demo?month=2020-04"
              ></StyledIfreme>
            </div>
              {/* <StyledEclipse $top="10%" $left="10%" $zIndex={0}></StyledEclipse> */}
          </div>
        </div>
      </main>
      <FooterSection />
    </>
  );
}

export default BookADemoPage;
