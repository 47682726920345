import styled from "styled-components";
import themeConfig from "../../lib/theme/tokens";
import { Badge } from "antd";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { useFireworkStore } from "../../store/fireworkStore";


const StyledNewsbar = styled.div`
    background-color: #25B06D;
    padding: 5px 10px 5px 10px;
    border-radius: 60px;
    color: white;
`;

const StyledNewsContainer = styled.div`
    background-color: ${themeConfig.colors.primary[500]};
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0px 15px 0px;
    color: white;
`;


function Newsbar() {
    const [windowDimensions] = useState(getWindowDimensions());
    const [isClose, setIsClose] = useState(false);
    const setIsFireworkOn = useFireworkStore((state: any) => state.setIsFireworkOn);

    const handleClose = () => {
        setIsClose(true);
        setIsFireworkOn({
            isFireworkOn: false
        })
    }

    return isClose || windowDimensions.width < 768 ? 
    <></>
    :
    (
        <StyledNewsContainer>
            <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
                <StyledNewsbar>
                    Exciting news!
                </StyledNewsbar>
                <span>Rasmal Company is thrilled to announce the acquisition of Pentugram ✨</span>
            </div>
            <div onClick={handleClose} style={{cursor: "pointer"}}>
                <CloseOutlined/>
            </div>
        </StyledNewsContainer>
    );
}

export default Newsbar;