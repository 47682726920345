import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, App as AntdApp } from "antd";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import theme from "./lib/theme";
import Hotjar from '@hotjar/browser';
import { MixpanelProvider } from 'react-mixpanel-browser';

const siteId = 3900103;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const MIXPANEL_CONFIG = {
  track_pageview: "full-url"
}

root.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <AntdApp>
        <MixpanelProvider config={MIXPANEL_CONFIG} token={process.env.REACT_APP_MIXPANEL_TOKEN}>
          <App />
        </MixpanelProvider>
      </AntdApp>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
