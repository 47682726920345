import styled from "styled-components";
import FooterSection from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Newsbar from "../../components/Newsbar/Newsbar";
import themeConfig from "../../lib/theme/tokens";
import { Checkbox, Form, Input, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import LocationIcon from "./components/LocationIcon";
import WorldWebIcon from "./components/WorldWebIcon";
import PaperPlaneIcon from "./components/PaperPlaneIcon";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledLoginButtonNavbar } from "../../lib/theme/components/Layout";
import { useFormStore } from "../../store/formStore";

const StyledContactTitle = styled.h1`
  color: #192a3e;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
`;

const StyledContactSubtitle = styled.p`
  color: #192a3e;
  font-size: 18px;
  text-align: center;
`;

const StyledContactContentContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
  justify-content: space-around;
  align-items: start;
  padding: 15px 0px 15px 0px;
  color: white;
`;

const StyledChatWithUsText = styled.div`
  color: #192a3e;
  font-size: 28px;
  text-align: left;
  width: fit-content;
  padding: 10px 10px 10px 1px;
  position: relative;
  margin-bottom: 20px;
  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 3px;
    background-color: ${themeConfig.colors.primary[500]};
    bottom: 0;
    left: 0;
  }
`;

const StyledFormContainer = styled(Form)`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;
`;

const StyledSubmitButton = styled(StyledLoginButtonNavbar)`
  padding: 12px;
  font-size: ${themeConfig.fonts.fontSizeNormal};
  border-radius: 4px;
`;

const StyledSmallInputContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
  gap: 32px;
  flex-basis: fit-content;
`;

const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: black;
  padding: 10px 0px 10px 0px;
  text-align: start;
  p {
    font-size: 18px;
  }
  a {
    font-size: 18px;
  }
`;

const StyledInfoContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
  gap: ${({ $isMobile }) => ($isMobile ? "64px" : "112px")};
  padding: 32px 0px 32px 0px;
`;

const StyledInfoTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #272731;
  margin-bottom: 8px;
  text-align: center;
`;

const StyledInfoSubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #414153;
  text-align: center;
`;

const StyledContactInputLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
`;

const StyledFormInputItem = styled(Form.Item)`
  text-align: left;
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  &.react-tel-input {
    input.form-control {
      width: 100%;
      height: 48px;
    }
  }
`;


function ContactPage() {
  const [windowDimensions] = useState(getWindowDimensions());
  // const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [policyAgreement, setPolicyAgreement] = useState(false);
  const formValues = useFormStore((state: any) => state.form);
  const setForm = useFormStore((state: any) => state.setForm);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [api, contextHolder]: any = notification.useNotification();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [formValues, form]);

  const openNotification = (
    type: string,
    message: string,
    description: string
  ) => {
    api[type]({
      message: message,
      description: description,
      placement: "bottomRight",
    });
  };

  const onSubmit = async () => {
    const tempFormValues = {...formValues};
    console.log(tempFormValues);
    setIsSubmitting(true);
    try {
      // await form.validateFields();
      form.setFields([
        { name: "first_name", errors: [] },
        { name: "last_name", errors: [] },
        { name: "email", errors: [] },
        { name: "phone_number", errors: [] },
        { name: "subject", errors: [] },
        { name: "message", errors: [] },
      ]);
      setForm({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        subject: "",
        message: "",
      });
      
    } catch (e) {
      return;
    }
    if (!policyAgreement) {
      openNotification("error", "Error", "Please agree to our privacy policy.");
      return;
    }
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/v2/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      setIsSubmitting(false);
      openNotification(
        "success",
        "Success",
        "Your message has been sent successfully."
      );
    } catch (e) {
      setForm(tempFormValues);
      setIsSubmitting(false);
      openNotification(
        "error",
        "Error",
        "An error occurred. Please try again later."
      );
    }
  };

  const validateForm = () => {
    if(formValues.first_name !== "" && formValues.last_name !== "" && formValues.email !== "" && formValues.phone_number !== "" && formValues.subject !== "" && formValues.message === "") {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      {contextHolder}
      <Newsbar />
      <Navbar activeSection="contact" />
      <main
        style={{
          background: "linear-gradient(180deg, #f6f9ff 0%, #ffffff 100%)",
          padding: windowDimensions.width < 768 ? "0 20px" : "0 120px 0 120px",
        }}
      >
        <div>
          <StyledContactTitle style={{ marginTop: "0", paddingTop: "96px" }}>
            We’d love to hear from you
          </StyledContactTitle>
          <StyledContactSubtitle style={{ marginTop: "0" }}>
            Our friendly team is always here to chat.
          </StyledContactSubtitle>
        </div>
        <StyledInfoContainer $isMobile={windowDimensions.width < 768}>
          <StyledInfoItem>
            <PaperPlaneIcon />
            <div style={{ textAlign: "center" }}>
              <StyledInfoTitle>Email</StyledInfoTitle>
              <StyledInfoSubtitle>
                Our friendly team is here to help.
              </StyledInfoSubtitle>
            </div>
            <a href="mailto:suuport@pentugram.com">suuport@pentugram.com</a>
          </StyledInfoItem>
          <StyledInfoItem>
            <LocationIcon />
            <div style={{ textAlign: "center" }}>
              <StyledInfoTitle>Office</StyledInfoTitle>
              <StyledInfoSubtitle>
                Come say hello at our office HQ.
              </StyledInfoSubtitle>
            </div>
            <a href="https://www.google.com/maps/place/RasMal+%7C+%D8%B1%D8%A7%D8%B3+%D9%85%D8%A7%D9%84%E2%80%AD/@24.7652208,46.6338424,16z/data=!4m15!1m8!3m7!1s0x3e2ee2fc7c53cc49:0x6afd80225f1756e6!2sAl+Aqiq,+Riyadh+13519!3b1!8m2!3d24.7672331!4d46.6391588!16s%2Fg%2F11rfrd7b13!3m5!1s0x3e2ee3c2889a3d35:0x461c208df5a8bc9e!8m2!3d24.762184!4d46.637957!16s%2Fg%2F11rk99ch67?entry=ttu">
              KAFD, 2822 – 6591, Riyadh 13519
            </a>
          </StyledInfoItem>
          <StyledInfoItem>
            <LocationIcon />
            <div style={{ textAlign: "center" }}>
              <StyledInfoTitle>Phone</StyledInfoTitle>
              <StyledInfoSubtitle>Mon-Fri from 8am to 5pm.</StyledInfoSubtitle>
            </div>
            <a href="tel:00966590291128">+966 59 029 1128</a>
          </StyledInfoItem>
        </StyledInfoContainer>
        <h1>Get in touch</h1>
        <p>We’d love to hear from you. Please fill out this form.</p>
        <StyledContactContentContainer
          $isMobile={windowDimensions.width < 768 ? true : false}
        >
          <div style={{ width: windowDimensions.width < 768 ? "100%" : "50%" }}>
            <StyledFormContainer
              initialValues={{
                first_name: formValues.first_name,
                last_name: formValues.last_name,
                email: formValues.email,
                phone_number: formValues.phone_number,
                subject: formValues.subject,
                message: formValues.message,
              }}
              form={form}
            >
              <StyledSmallInputContainer
                $isMobile={windowDimensions.width < 768 ? true : false}
              >
                <StyledFormInputItem
                  name="first_name"
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <StyledContactInputLabel htmlFor="first_name">
                    First Name
                  </StyledContactInputLabel>
                  <Input
                    type="text"
                    placeholder="First Name"
                    value={formValues.first_name}
                    onChange={(e) => {
                      setForm({ ...formValues, first_name: e.target.value });
                    }}
                    style={{ padding: "12px" }}
                  />
                </StyledFormInputItem>
                <StyledFormInputItem
                  name="last_name"
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <StyledContactInputLabel htmlFor="first_name">
                    Last Name
                  </StyledContactInputLabel>
                  <Input
                    type="text"
                    value={formValues.last_name}
                    placeholder="Last Name"
                    onChange={(e) => {
                      setForm({ ...formValues, last_name: e.target.value });
                    }}
                    style={{ padding: "12px" }}
                  />
                </StyledFormInputItem>
              </StyledSmallInputContainer>

              <StyledFormInputItem
                rules={[{ required: true }]}
                name="email"
                style={{ width: "100%" }}
              >
                <StyledContactInputLabel htmlFor="first_name">
                  Email
                </StyledContactInputLabel>

                <Input
                  type="text"
                  value={formValues.email}
                  placeholder="Email"
                  onChange={(e) => {
                    setForm({ ...formValues, email: e.target.value });
                  }}
                  style={{ padding: "12px" }}
                />
              </StyledFormInputItem>
              <StyledFormInputItem
                rules={[{ required: true }]}
                name="phone_number"
                style={{ width: "100%" }}
              >
                <StyledContactInputLabel htmlFor="first_name">
                  Phone Number
                </StyledContactInputLabel>
                <StyledPhoneInput
                  placeholder="Phone Number"
                  enableSearch={true}
                  country={"sa"}
                  value={formValues.phone_number}
                  onChange={(phone) => {
                    setForm({ ...formValues, phone_number: phone });
                  }}
                />
              </StyledFormInputItem>
              <StyledFormInputItem rules={[{ required: true }]} name="subject">
                <StyledContactInputLabel htmlFor="subject">
                  Subject
                </StyledContactInputLabel>
                <Input
                  placeholder="Subject"
                  value={formValues.subject}
                  onChange={(e) => {
                    setForm({ ...formValues, subject: e.target.value });
                  }}
                  style={{ padding: "12px", width: "100%" }}
                />
              </StyledFormInputItem>
              <StyledFormInputItem rules={[{ required: true }]} name="message">
                <StyledContactInputLabel htmlFor="message">
                  Message
                </StyledContactInputLabel>
                <TextArea
                  rows={6}
                  placeholder="Message"
                  value={formValues.message}
                  onChange={(e) => {
                    setForm({ ...formValues, message: e.target.value });
                  }}
                  style={{ padding: "12px", width: "100%" }}
                />
              </StyledFormInputItem>
              <Checkbox
                value={policyAgreement}
                onChange={() => setPolicyAgreement(!policyAgreement)}
              >
                You agree to our friendly{" "}
                <span
                  onClick={() => navigate("/privacy-policy")}
                  style={{ textDecoration: "underline" }}
                >
                  privacy policy.
                </span>
              </Checkbox>
              <StyledSubmitButton
                onClick={onSubmit}
                disabled={!policyAgreement || isSubmitting}
              >
                Send Message
              </StyledSubmitButton>
            </StyledFormContainer>
          </div>
        </StyledContactContentContainer>
      </main>
      <FooterSection />
    </>
  );
}

export default ContactPage;
