import { useState } from "react";
import {
  Section,
  StyledSectionHeader,
  StyledSectionSubHeader,
} from "../../shared/components/Section";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import SectionSlider from "../../shared/components/Slider";
import themeConfig from "../../lib/theme/tokens";
import Slack from "../../assets/images/slack.png";
import Dropbox from "../../assets/images/dropbox.png";
import GoogleDrive from "../../assets/images/google-drive.png";
import GoogleCalender from "../../assets/images/google-calender.png";

function ToolsSection() {
  const [windowDimensions] = useState(getWindowDimensions());

  return (
    <Section
      id="tools-section"
      $padding={
        windowDimensions.width > 768
          ? "96px 112px 96px 112px"
          : "96px 50px 96px 50px"
      }
      style={{ backgroundColor: themeConfig.colors.primary[50] }}
    >
      <StyledSectionHeader>Get more value from your tools</StyledSectionHeader>
      <StyledSectionSubHeader>
        Connect your tools, connect your teams. With apps already available in
        our directory, your team’s favourites tools are just a click away.
      </StyledSectionSubHeader>
      <div style={{ width: "100%", marginTop: "64px" }}>
        <SectionSlider isMobile={windowDimensions.width < 768} slidesToShow={windowDimensions.width > 768 ? 4 : 1}>
          <div>
            <img src={Slack} alt="slack-icon" />
          </div>
          <div>
            <img src={Dropbox} alt="dropbox-icon" />
          </div>
          <div>
            <img src={GoogleDrive} alt="google-drive-icon" />
          </div>
          <div>
            <img src={GoogleCalender} alt="google-calender-icon" />
          </div>
        </SectionSlider>
      </div>
    </Section>
  );
}

export default ToolsSection;
