import { create } from "zustand";

interface Form {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
}

export const useFormStore = create((set) => ({
    form: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        subject: "",
        message: "",
    },
    setForm: (form : Form) => set({ form }),   
}));