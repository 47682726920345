import styled from "styled-components";
import themeConfig from "../../lib/theme/tokens";

export const Section = styled.section<{$padding: string}>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.$padding};
  align-items: center;
`;

export const StyledSectionHeader = styled.h1`
  font-weight: 700;
  font-size: 32px;
  color: ${themeConfig.colors.natural[800]};
`;

export const StyledSectionSubHeader = styled.h2`
  font-weight: 400;
  font-size: 18px;
  color: ${themeConfig.colors.secondary};
`;
