import { useLocation } from "react-router-dom";
import FooterSection from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Newsbar from "../../components/Newsbar/Newsbar";
import { useEffect, useState } from "react";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";

function PrivacyPolicyPage() {

  const location = useLocation();
  const [windowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <Newsbar />
      <Navbar />
      <main style={{ padding: windowDimensions.width > 768 ? "0 30px" : "70px 30px 0 30px"}}>
        <div>
          <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
          <p style={{ textAlign: "center" }}>
            We take data privacy very seriously
          </p>
        </div>
        <div style={{ textAlign: "left" }}>
          <h2>INTRODUCTION</h2>
          <div>
            <p>
              This Privacy Policy sets out how Pentugram Holding Ltd
              <strong>(“Pentugram,” “we,” and “our”) and “our”)</strong> uses
              and protects any Personal Data (defined below) that we collect,
              receive and store about individuals in connection with the use of:
              (i) the websites at www.Pentugram.com and any other website that
              we operate (collectively, together with their sub-domains, content
              and services, the “Sites”) and visit of any of such Sites; and
              (ii) our project management tool and related services available
              via the Sites and our web and mobile applications (the{" "}
              <strong>“Service”</strong>). Pentugram is committed to protecting
              your privacy and adopt best practice in compliance with
              appropriate privacy law and regulations, including the EU General
              Data Protection Regulation (“GDPR”).
            </p>
            <p>
              We ourselves do not host any of the Sites and Service. All hosting
              is done by third-party service providers that we engage. This
              means that data you provide us or that we collect from you
              (including any Personal Data, as defined below) – as further
              described in this Privacy Policy – is hosted with such third-party
              service providers on servers that they own or control. Regardless
              of where such third-party service providers are located, their
              servers may be located anywhere in the world. Your data may even
              be replicated across multiple servers located in multiple
              countries.{" "}
              <strong>
                By using our Website or registering for our Service, you consent
                to the collection and use of your information as described in
                this Policy.
              </strong>
            </p>
            <p>
              We may change this policy from time to time by updating this page.
              You should check this page from time to time to ensure that you
              are happy with any changes. This policy is effective from 1st
              November 2018.
            </p>
            <p>
              The policy is intended to be read in conjunction with our Terms of
              Service. Unless otherwise defined in this Privacy Policy, the
              terms used in this Privacy Policy have the same meanings as in our
              Terms of Service. For the purpose of this Privacy Policy, the data
              controller is{" "}
              <strong>
                Pentugram Holding Ltd, formation number 000001487, “duly
                registered in Abu Dhabi Global Market”
              </strong>
              , with its registered office at: 2406 ResCo-work02, Level 24, AI,
              Sila Tower, ADGM, Al Maryah Island, UAE.
            </p>
            <ol>
              <li>
                <strong>PURPOSES OF PROCESSING</strong>
                <ul>
                  <li>
                    <strong>What is personal data?</strong>
                    <p>
                      We collect information about you in a range of forms,
                      including personal data. As used in this Policy,
                      â€œpersonal dataâ€ is as defined in the General Data
                      Protection Regulation, this includes any information
                      which, either alone or in combination with other
                      information we process about you, identifies you as an
                      individual, including, for example, your name, postal
                      address, email address and telephone number.
                    </p>
                  </li>
                  <li>
                    <strong>Why do we need your personal data?</strong>
                    <p>
                      We will only process your personal data in accordance with
                      applicable data protection and privacy laws. We need
                      certain personal data in order to provide you with access
                      to our Website and Service. If you are registering with
                      us, you will be asked to tick to agree to provide this
                      information in order to access our Website/ Service,
                      subscribe to our products, or view our content. This
                      consent provides us with the legal basis we require under
                      applicable law to process your data. You maintain the
                      right to withdraw such consent at any time. If you do not
                      agree to our use of your personal data in line with this
                      Policy, please do not use our Website or subscribe to our
                      Service.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <strong>COLLECTING YOUR PERSONAL DATA</strong>
                <p>We collect information about you in the following ways:</p>
                <ul>
                  <li>
                    <strong>Information You Give Us</strong>
                    <p>This includes:</p>
                    <ul>
                      <li>
                        <p>
                          the personal data you provide when you register to use
                          our Website/Service, including yourâ€Ž name, postal
                          address, email address, telephone number, username,
                          password and demographic information (such as your
                          gender);
                        </p>
                      </li>
                      <li>
                        <p>
                          the personal data you provide for financial account
                          information, such as credit card number and other
                          payment information. Please note, we do not retain
                          credit card information;
                        </p>
                      </li>
                      <li>
                        <p>
                          the personal data you provide when you subscribe to
                          our services ,what you subscribe, and how frequently
                          you subscribe;
                        </p>
                      </li>
                      <li>
                        <p>
                          the personal data that may be contained in any video,
                          comment or other submission you upload or post to the
                          Website;
                        </p>
                      </li>
                      <li>
                        <p>
                          the personal data you provide in connection with our
                          rewards program and other promotions we run on the
                          Website;
                        </p>
                      </li>
                      <li>
                        <p>
                          the personal data you provide when you report a
                          problem with our Website/Service or when we provide
                          you with customer support;
                        </p>
                      </li>
                      <li>
                        <p>
                          the personal data you provide when you correspond with
                          us by phone, email or otherwise;
                        </p>
                      </li>
                      <li>
                        <p>
                          the personal data you provide when you connect your
                          account to another service such as LinkedIn, Facebook
                          or Twitter, the other service may send us your
                          registration or profile information on that service
                          that you authorize. This information enables
                          cross-platform data sharing to improve your user
                          experience.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Information We Automatically Collect</strong>
                    <p>
                      Whenever you visit or interact with the Website, we, as
                      well as any of our Service providers may use cookies, web
                      beacons, local shared objects (Flash Cookies), or other
                      technologies to automatically or passively collect
                      information about your online activity and Website use
                      information. For information about our use of cookies and
                      other similar technologies through the Website, you can
                      read our Cookie section below. Please note that we
                      automatically collect the following information about you:
                    </p>
                    <ul>
                      <li>
                        <strong>Log In Information.</strong>
                        We may automatically collect Log in information when you
                        visit our Website. This may include information about
                        your Internet service provider, your operating system,
                        browser type, domain name, Internet protocol (IP)
                        address, your access times, the website that referred
                        you to us, the Web pages you request, and the date and
                        time of those requests.
                      </li>
                      <li>
                        <strong>Third Party Service Providers. </strong>
                        We may use third party service providers to support our
                        Website. Some of these service providers may use
                        technology such as cookies, web beacons, pixel tags, log
                        files, or Flash cookies to receive, collect, and store
                        information.
                      </li>
                      <li>
                        <strong>Third Party Analytic Technologies. </strong>
                        We may use third partiesâ€™ analytics and tracking tools
                        to better understand who is using our Service, how users
                        are using the Website, how to improve the Service
                        effectiveness, our services and related content, to help
                        us or those third parties serve more targeted
                        advertising to you across the Internet, and to allow
                        â€œLikeâ€ buttons and â€œShareâ€ buttons to work. These
                        tools may use technology such as cookies, web beacons,
                        pixel tags, log files, Flash cookies, HTML5 cookies, or
                        other technologies to automatically collect and store
                        certain information. They may also combine information
                        they collect from your interaction with the Website with
                        information they collect from other sources. We do not
                        have access to, or control over, these third partiesâ€™
                        use of cookies or other tracking technologies.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Information from Social Networking Sites</strong>
                    <p>
                      Our Website include interfaces that allow you to connect
                      with social networking sites (each a â€œSNSâ€). If you
                      connect to a SNS through our Website, you authorize us to
                      access, use and store the information that you agreed the
                      SNS could provide to us based on your settings on that
                      SNS. We will access, use and store that information in
                      accordance with this Policy. You can revoke our access to
                      the information you provide in this way at any time by
                      amending the appropriate settings from within your account
                      settings on the applicable SNS.
                    </p>
                  </li>
                  <li>
                    <strong>Information Collected From Other Sources</strong>
                    <p>
                      We may acquire information from other trusted sources to
                      update or supplement the information that you provide or
                      that we collect automatically. We may use this information
                      to help us maintain the accuracy of the information we
                      collect, to target our communications so that we can
                      inform you of products, services or other offers that may
                      be of interest to you, and for internal business analysis
                      or other business purposes.
                    </p>
                  </li>
                  <li>
                    <strong>Combination of Information</strong>
                    <p>
                      We may combine the information we receive from and about
                      you, including information you provide to us and
                      information we automatically collect through our online
                      services, as well as information collected offline, across
                      other computers or devices that you may use, and from
                      third party sources.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Cookies & Similar Technologies</strong>
                <p>
                  We, or third-party service providers acting on our behalf, may
                  use cookies or web beacons on our Website. A cookie is a small
                  text file that may be stored on the hard drive of your
                  computer when you access the Website. A web beacon is an
                  electronic image used to recognize a cookie on your computer
                  when you view a web page. We may use cookies and web beacons
                  to collect non-personal data from you, including your IP
                  address, browser type, the time and length of your visit, the
                  pages of the Website you visit and other clickstream data.
                  This information may be used in order to:
                </p>
                <ol>
                  <li>recognize you when you return to the Website;</li>
                  <li>
                    improve and personalize your experience on our Website;
                  </li>
                  <li>provide you with customized advertising; and</li>
                  <li>
                    monitor Website usage and analyze the behavior of Website
                    visitors.
                  </li>
                </ol>
                <p>
                  We do not collect any personal data through our use of cookies
                  and web beacons, and we do not combine the non-personal data
                  we collect through them with any of your personal information.
                  You may decline cookies, but by doing so, you may not be able
                  to use certain features on our Website. You also may be able
                  to make some web beacons unusable by rejecting cookies. Check
                  the “Help” menu of your browser to learn how to change your
                  cookie preferences.
                </p>
              </li>
              <li>
                <strong>Disabling Cookies</strong>
                <p>
                  You can typically remove or reject cookies via your browser
                  settings. In order to do this, follow the instructions
                  provided by your browser (usually located within the
                  “settings,” “help” “tools” or “edit” facility). Many browsers
                  are set to accept cookies until you change your settings.
                </p>
                <p>
                  If you do not accept our cookies, you may experience some
                  inconvenience in your use of our Website. For example, we may
                  not be able to recognize your computer or mobile device and
                  you may need to log in every time you visit our Website.
                </p>
              </li>
              <li>
                <strong>Using Your Personal Data</strong>
                <p>
                  We may use personal data we collect from and about you for a
                  variety of business purposes, including to:
                </p>
                <ol>
                  <li>
                    to operate, maintain, and improve our Website and Service;
                  </li>
                  <li>
                    to manage your account, including to communicate with you
                    regarding your subscriptions, purchases, accounts, and
                    requests for information if you have an account on with us;
                  </li>
                  <li>
                    to operate and administer our rewards program and other
                    promotions you participate in on our Website;
                  </li>
                  <li>
                    to respond to your comments and questions and to provide
                    customer service;
                  </li>
                  <li>
                    to send information including technical notices, updates,
                    security alerts, and support and administrative messages;
                  </li>
                  <li>
                    with your consent, to send you marketing e-mails about
                    upcoming promotions, and other news, including information
                    about products and services offered by us and our
                    affiliates. You may opt-out of receiving such information at
                    any time: such marketing emails tell you how to “opt-out.”
                    Please note, even if you opt out of receiving marketing
                    emails, we may still send you non-marketing emails.
                    Non-marketing emails include emails about your account with
                    us and our business dealings with you;
                  </li>
                  <li>
                    to process payments you make via our Website- We use the
                    external payment system to process all our online payments.
                    They have their own security and our data protection
                    legislation compliant. We do not store your credit card
                    details and we do not store your card’s security/CVV code.
                  </li>
                  <li>
                    as we believe necessary or appropriate (a) to comply with
                    applicable laws; (b) to comply with lawful requests and
                    legal process, including to respond to requests from public
                    and government authorities; (c) to enforce our Policy; and
                    (d) to protect our rights, privacy, safety or property,
                    and/or that of you or others;;
                  </li>
                  <li>
                    address problems with the Service or our business, and to
                    protect the security or integrity of the Website and our
                    business
                  </li>
                  <li>for analysis and study services; and</li>
                  <li>
                    as described in the “Sharing of your Personal Data” section
                    below We also use anonymous or aggregated information to
                    allow us to audit site usage, create marketing profiles,
                    manage our relationship with advertisers, and to provide
                    support services to us. This processing may involve
                    profiling – for example to create audiences based on
                    demographic or location-based information for marketing and
                    customization.
                  </li>
                </ol>
                <p>We will have a lawful basis for processing your data if:</p>
                <ol>
                  <li>
                    We need to process your information in order to provide you
                    with the products or service you have requested or to enter
                    into a contract;
                  </li>
                  <li>You have consented to such processing;</li>
                  <li>
                    We have a legitimate interest for processing your data e.g.,
                    for fraud prevention; network and information systems
                    security; data analytics; enhancing, modifying or improving
                    our services; identifying usage trends; and/or
                  </li>
                  <li>We are legally obliged to process it.</li>
                </ol>
              </li>
              <li>
                <strong>Sharing Your Personal Data</strong>
                <p>
                  We do not share your personal data except in the limited
                  circumstances described here. We may share your personal data
                  with:
                </p>
                <ol>
                  <li>
                    <strong style={{ fontStyle: "italic" }}>
                      Our Affiliates:
                    </strong>
                    We may share your data with our affiliates and subsidiaries
                    bearing the Pentugram brand for business, operational,
                    promotional and marketing purposes.
                  </li>
                  <li>
                    <strong style={{ fontStyle: "italic" }}>
                      Third Party Service Providers:
                    </strong>
                    We may share your data with third party service providers
                    that provide business, professional or technical support
                    functions for us (including to the extent necessary or
                    desirable to fulfill or complete your transaction), help us
                    operate our business and Website, or administer activities
                    on our behalf.
                  </li>
                  <li>
                    <strong style={{ fontStyle: "italic" }}>
                      Legal Obligations; Safety:
                    </strong>
                    We may access and disclose your data to respond to
                    subpoenas, judicial processes, or government requests and
                    investigations, or in connection with an investigation on
                    matters related to public safety, as permitted by law, or
                    otherwise as required by law. We may disclose your
                    information to protect the security of our Website, servers,
                    network systems, and databases. We also may disclose your
                    information as necessary, if we believe that there has been
                    a violation of our Terms of Service, any other legal
                    document or contract related to our Service, or the rights
                    of any third party.
                  </li>
                  <li>
                    <strong style={{ fontStyle: "italic" }}>
                      Sale or Transfer of Business or Assets:
                    </strong>
                    We may sell or purchase assets during the normal course of
                    our business. If another entity acquires us or any of our
                    assets, data we have collected about you may be transferred
                    to such entity. In addition, if any bankruptcy or
                    reorganization proceeding is brought by or against us, such
                    data may be considered an asset of ours and may be sold or
                    transferred to third parties. Should such a sale or transfer
                    occur, we will use reasonable efforts to try to require that
                    the transferee use personal data provided through our
                    Service in a manner that is consistent with this Privacy
                    Policy.
                  </li>
                  <li>
                    <strong style={{ fontStyle: "italic" }}>
                      Aggregate or Anonymous Non-Personal Information:
                    </strong>
                    We may also share aggregate, anonymous, or de-identified
                    non-personal information with third parties for their
                    marketing or analytics uses.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Third Party Sites</strong>
                <p>
                  This Privacy Policy only addresses the collection, processing
                  and use (including disclosure) of data by us. This Website may
                  contain various links to third-party websites. These sites may
                  provide additional information, goods, services and/or
                  promotions. These sites are owned and operated independently
                  of Pentugram, and have their own separate privacy and data
                  collection practices. Any information you provide to these
                  Sites will be governed under the terms of their privacy
                  policy, if any. Pentugram has no responsibility or liability
                  whatsoever for the independent actions or policies of these
                  independent sites, and is not responsible for the content or
                  privacy practices of such sites.
                </p>
              </li>
              <li>
                <strong>Payments</strong>
                <p>
                  We may provide paid products and/or services within the
                  Service. In that case, we use third-party services for payment
                  processing (e.g. payment processors).
                </p>
                <p>
                  We will not store or collect your payment card details. That
                  information is provided directly to our third-party payment
                  processors whose use of your personal information is governed
                  by their Privacy Policy. These payment processors adhere to
                  the standards set by PCI-DSS as managed by the PCI Security
                  Standards Council, which is a joint effort of brands like
                  Visa, MasterCard, American Express and Discover. PCI-DSS
                  requirements help ensure the secure handling of payment
                  information.
                </p>
              </li>
              <li>
                <strong>User Generated Content</strong>
                <p>
                  You may share personal data with us when you submit user
                  generated content through your account, including via our
                  rewards program, forums, message boards and blogs on our
                  Website. Please note that any information you post or disclose
                  on our Website will become public information, and will be
                  available to other users of our Website and to the general
                  public. We urge you to be very careful when deciding to
                  disclose your personal data, or any other information, on our
                  Website. Such personal data and other information will not be
                  private or confidential once it is published on our Website.
                </p>
                <p>
                  If you provide feedback to us, we may use and disclose such
                  feedback on our Website, provided we do not associate such
                  feedback with your personal data. If you have provided your
                  consent to do so, we may post your first and last name along
                  with your feedback on our Website. We will collect any
                  information contained in such feedback and will treat the
                  personal data in it in accordance with this Policy.
                </p>
              </li>
              <li>
                <strong>International Data Transfer</strong>
                <p>
                  Your information, including personal data that we collect from
                  you, may be transferred to, stored at and processed by us
                  outside the country in which you reside, where data protection
                  and privacy regulations may not offer the same level of
                  protection as in other parts of the world. By accepting this
                  Policy, you agree to this transfer, storing or processing. We
                  will take all steps reasonably necessary to ensure that your
                  data is treated securely and in accordance with this Policy.
                </p>
              </li>
              <li>
                <strong>Security</strong>
                <p>
                  We seek to use reasonable organizational, technical and
                  administrative measures to protect personal data within our
                  organization. Unfortunately, no transmission or storage system
                  can be guaranteed to be completely secure, and transmission of
                  information via the internet is not completely secure. If you
                  have reason to believe that your interaction with us is no
                  longer secure (for example, if you feel that the security of
                  any account you might have with us has been compromised),
                  please immediately notify us of the problem by contacting us.
                </p>
              </li>
              <li>
                <strong>Data Retention</strong>
                <p>
                  We will retain your personal data as necessary to comply with
                  our legal obligations, resolve disputes, and enforce our
                  agreements. We will also keep your personal data for as long
                  as your account is active or as needed to provide you services
                  and to maintain a record of your transactions for financial
                  reporting purposes. We retain archived copies of your
                  information as required by law or for legitimate business
                  purposes.
                </p>
              </li>
              <li>
                <strong>Our Policy On Children</strong>
                <p>
                  Our Website and Service is not directed to children less than
                  18 years of age. If a parent or guardian becomes aware that
                  his or her child has provided us with information without
                  their consent, he or she should contact us. We will delete
                  such information from our files as soon as reasonably
                  practicable.
                </p>
              </li>
              <li>
                <strong>Your Rights</strong>
                <ol>
                  <li>
                    <strong>Opt-out.</strong>
                    You may contact us anytime to opt-out of: (i) direct
                    marketing communications; (ii) automated decision-making
                    and/or profiling; (iii) our collection of sensitive personal
                    data; (iv) any new processing of your personal data that we
                    may carry out beyond the original purpose; or (v) the
                    transfer of your personal data outside the EEA. Please note
                    that your use of some of the Website may be ineffective upon
                    opt-out.
                  </li>
                  <li>
                    <strong>Access.</strong>
                    You may access the information we hold about you at any time
                    via your profile/account or by contacting us directly.
                  </li>
                  <li>
                    <strong>Amend.</strong>
                    You can also contact us to update or correct any
                    inaccuracies in your personal data.
                  </li>
                  <li>
                    <strong>Move.</strong>
                    Your personal data is portable i.e. you have the flexibility
                    to move your data to other service providers as you wish.
                  </li>
                  <li>
                    <strong>Erase and forget.</strong>
                    In certain situations, for example when the information we
                    hold about you is no longer relevant or is incorrect, you
                    can request that we erase your data.
                  </li>
                </ol>
                <p>
                  If you wish to exercise any of these rights, please contact
                  us. In your request, please make clear: (i){" "}
                  <strong>what</strong> personal data is concerned; and (ii){" "}
                  <strong>which of the above rights</strong> you would like to
                  enforce. For your protection, we may only implement requests
                  with respect to the personal data associated with the
                  particular email address that you use to send us your request,
                  and we may need to verify your identity before implementing
                  your request. We will try to comply with your request as soon
                  as reasonably practicable and in any event, within one month
                  of your request. Please note that we may need to retain
                  certain information for recordkeeping purposes and/or to
                  complete any transactions that you began prior to requesting
                  such change or deletion.
                </p>
              </li>
              <li>
                <strong>Complaints</strong>
                <p>
                  We are committed to resolve any complaints about our
                  collection or use of your personal data. If you would like to
                  make a complaint regarding this Policy or our practices in
                  relation to your personal data, please contact us at:……………….
                  We will reply to your complaint as soon as we can and in any
                  event, within 30 days. We hope to resolve any complaint
                  brought to our attention, however if you feel that your
                  complaint has not been adequately resolved, you reserve the
                  right to contact your local data protection supervisory
                  authority
                </p>
              </li>
              <li>
                <strong>Contact Information</strong>
                <p>
                  We welcome your comments or questions about this Policy. You
                  may contact us in writing at <a href="mailto:support@pentugram.com">support@pentugram.com</a>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </main>
      <FooterSection />
    </>
  );
}

export default PrivacyPolicyPage;
