import { ThemeConfig } from "antd";
import themeToken  from "./tokens"
import {  } from "./components/Layout"

const theme = {
    token: {
        ...themeToken,
        fontFamily: "plus-jakarta-sans, sans-serif !important"
    },
    components: {
    }
} as ThemeConfig;

export default theme;

