const colors = {
    black: '#000000',
    white: '#FFFFFF',
    primary: {
        25: "#F5F8FD",
        50: "#EFF4FB",
        500: "#3070CC",
        600: "#265AA3",
    },
    secondary: "#414153",
    natural: {
        25: "#F8F8F9",
        50: "#F3F3F6",
        100: "#E8E8EC",
        400: "#8989A1",
        500: "#6C6C89",
        700: "#414153",
        800: "#343442"
    },
    textSecondary: "#414153",
}

export default colors;