import { forwardRef, useState } from "react";
import {
  Section,
  StyledSectionHeader,
  StyledSectionSubHeader,
} from "../../shared/components/Section";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import themeConfig from "../../lib/theme/tokens";
import SectionSlider from "../../shared/components/Slider";
import AmamVentures from "../../assets/images/Amam-Ventures.png";
import MNF from "../../assets/images/MNF.png";
import Oassis500 from "../../assets/images/Oassis500.png";
import TechInvestCom1 from "../../assets/images/TechInvestCom-1.png";
import Metafin from "../../assets/images/metafin.png";
import { Image } from "antd";

const TrustedBySection = forwardRef((props: any, ref: any) => {
  const [windowDimensions] = useState(getWindowDimensions());

  const openLink = (link: string) => {
    window.open(link, "_blank");
  }

  return (
    <Section
      id="our-clients"
      ref={ref}
      $padding={
        windowDimensions.width > 768
          ? "96px 112px 96px 112px"
          : "96px 50px 96px 50px"
      }
    >
      <StyledSectionHeader>Trusted by</StyledSectionHeader>
      <StyledSectionSubHeader>
        Trusting the app to make their work easier
      </StyledSectionSubHeader>
      <div style={{ width: "100%", marginTop: "64px" }}>
        <SectionSlider isMobile={windowDimensions.width < 768} slidesToShow={windowDimensions.width > 768 ? 4 : 1}>
          <div onClick={() => openLink("https://www.amamventures.com/")}>
            <img src={AmamVentures} alt="AmamVentures-icon" />
          </div>
          <div onClick={() => openLink("https://mnf.ma/")}>
            <img src={MNF} alt="MNF-icon" />
          </div>
          <div onClick={() => openLink("https://oasis500.com/")}>
            <img src={Oassis500} alt="Oassis500-icon" />
          </div>

          <div onClick={() => openLink("https://arzanvc.com/")}>
            <img src={TechInvestCom1} alt="TechInvestCom1-icon" />
          </div>
          <div onClick={() => openLink("https://www.metafinpartners.com/")}>
            <img src={Metafin} alt="metafin-icon" />
          </div>
        </SectionSlider>
      </div>
    </Section>
  );
});

export default TrustedBySection;
