import React from "react";
import styled from "styled-components";
import themeConfig from "../../../lib/theme/tokens";
import { Divider } from "antd";
import { useSpring, animated } from "@react-spring/web";
import { StyledLoginButtonNavbar } from "../../../lib/theme/components/Layout";
import { useMixpanel } from "react-mixpanel-browser";

interface PackageCardProps {
  title: string;
  price: string;
  features: React.ReactElement[];
  isPopular: boolean;
  pricePerioud: string;
  springs: any;
}

const StyledPackageCard = styled.div`
  padding: 32px;
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledPackageCardHeader = styled.div``;

const StyledPackageCardTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: ${themeConfig.colors.natural[500]};
`;

const StyledPackageCardPrice = styled.span`
  font-size: 60px;
  font-weight: 700;
  color: ${themeConfig.colors.natural[800]};
`;

const StyledPackageCardList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledPackageCardListItem = styled.li`
  font-size: 16px;
  font-weight: 400;
  text-align: left;

  &:before {
    content: "✔";
    color: ${themeConfig.colors.primary[500]};
    font-weight: 500;
    font-size: 20px;
    display: inline-block;
    width: 1em;
    padding-right: 8px;
  }
`;

const StyledPackageCardButton = styled(StyledLoginButtonNavbar)`
  padding: 12px;
  font-size: 18px;
  border-radius: 8px;
  font-weight: 600;
`;

const StyledPricePerioud = styled.sub`
  font-size: 16px;
  font-weight: 500;
  padding-left: 8px;
  padding-top: 18px;
  color: ${themeConfig.colors.natural[500]};
`;

const StyledPopularBadge = styled.div`
  background-color: #eef9f4;
  padding: 2px 8px;
  height: 50%;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #25b06d;
`;

function PackageCard({
  title,
  price,
  features,
  isPopular,
  pricePerioud,
  springs,
}: PackageCardProps) {
  const mixpanel = useMixpanel();
  return (
    <animated.div
      style={{
        padding: "32px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        ...springs,
      }}
    >
      <StyledPackageCardHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <StyledPackageCardTitle>{title}</StyledPackageCardTitle>
          {isPopular && <StyledPopularBadge>Popular</StyledPopularBadge>}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <StyledPackageCardPrice>{price}</StyledPackageCardPrice>
          <StyledPricePerioud>/{pricePerioud}</StyledPricePerioud>
        </div>
      </StyledPackageCardHeader>
      <p style={{ textAlign: "left", color: themeConfig.colors.natural[500] }}>
        Per User
      </p>
      <StyledPackageCardButton
        onClick={() => {
          window.location.href = "https://app.pentugram.com";
          mixpanel.track(title , {
            package: title,
            packagePeriod: pricePerioud,
          });
        }}
      >
        Get Started
      </StyledPackageCardButton>
      <Divider />
      <StyledPackageCardList>
        {features.map((feature, index) => {
          return (
            <StyledPackageCardListItem key={index}>
              {feature}
            </StyledPackageCardListItem>
          );
        })}
      </StyledPackageCardList>
    </animated.div>
  );
}

export default PackageCard;
