import { useState } from "react";
import { getWindowDimensions } from "../../../../utils/getWindowDimesnsions";
import { StyledBookADemoFeature, StyledCheckOutlined, StyledContentContainer, StyledDealFeatureContainer, StyledFeatureTitle, StyledFeautreDescription, StyledLineChartOutlined, StyledUnorderListItem, StyledUnorderedList, StyledUnorderedListItemContainer } from "../../../../lib/theme/components/Features";
import themeConfig from "../../../../lib/theme/tokens";
import { Image } from "antd";
import { useNavigate } from "react-router-dom";
import FeatureDocumentIcon from "../../../../assets/images/document-text.png"; 
import FeatureReportImage from "../../../../assets/images/Mockup-wrap-3.png";



function ReportFeature() {
    const navigate = useNavigate();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    

    return (
        <StyledDealFeatureContainer style={{flexDirection: windowDimensions.width < 768 ? "column": "row"}}>
            <StyledContentContainer style={windowDimensions.width > 768 ?  {width: "50%" } : {}}>
                <img src={FeatureDocumentIcon}  alt="feature document icon"/>
                <StyledFeatureTitle>Report Easily</StyledFeatureTitle>
                <StyledFeautreDescription>Build your customized reports, merge between the Ritch editors with having all the data in its back-office. templates saved will let you build your next report in no time.</StyledFeautreDescription>
                <StyledUnorderedList>
                    <StyledUnorderedListItemContainer><StyledCheckOutlined style={{color: themeConfig.colors.primary[500]}} /> <StyledUnorderListItem>Build your reports for internal and external use</StyledUnorderListItem></StyledUnorderedListItemContainer>
                    <StyledUnorderedListItemContainer><StyledCheckOutlined style={{color: themeConfig.colors.primary[500]}} /> <StyledUnorderListItem>Visualize your data in a click of a button</StyledUnorderListItem></StyledUnorderedListItemContainer>
                    <StyledUnorderedListItemContainer><StyledCheckOutlined style={{color: themeConfig.colors.primary[500]}} /> <StyledUnorderListItem>Save report as a template</StyledUnorderListItem></StyledUnorderedListItemContainer>
                </StyledUnorderedList>
                <StyledBookADemoFeature onClick={() => navigate("/book-a-demo")} style={windowDimensions.width < 768 ? { marginBottom: "32px" } : {}}>Book a demo</StyledBookADemoFeature>
            </StyledContentContainer>
            <div style={windowDimensions.width > 768 ?  {width: "50%" } : {}}>
                <Image preview={false} src={FeatureReportImage}/>
            </div>
        </StyledDealFeatureContainer>
    );
}

export default ReportFeature;