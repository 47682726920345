import styled from "styled-components";
import FooterSection from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Newsbar from "../../components/Newsbar/Newsbar";
import QuestionMarkIcon from "./components/QuestionMarkIcon";
import themeConfig from "../../lib/theme/tokens";
import { getWindowDimensions } from "../../utils/getWindowDimesnsions";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FaqItem from "./components/FaqItem";
import {
  StyledSectionHeader,
  StyledSectionSubHeader,
} from "../../shared/components/Section";
import BookADemoPage from "../BookADemoPage/BookADemoPage";
import StartTrialSection from "../../components/StartTrialSection/StartTrialSection";

const StyledQuestionsContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: ${(({$isMobile}) => $isMobile ? "64px 32px" : "64px 336px")};
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const StyledQuestionMarkContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const StyledAskQuestionButton = styled.button`
  background-color: ${themeConfig.colors.primary[500]};
  color: ${themeConfig.colors.white};
  border-radius: 4px;
  padding: 12px 24px 12px 24px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  border: none;
  margin-top: 20px;
  &:hover {
    background-color: ${themeConfig.colors.white};
    color: ${themeConfig.colors.primary[500]};
    border: 1px solid ${themeConfig.colors.primary[500]};
  }
`;

function FAQsPage() {
  const [windowDimensions] = useState(getWindowDimensions());

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Newsbar />
      <Navbar />
      <main
        style={{
          background: "linear-gradient(180deg, #f6f9ff 0%, #ffffff 100%)",
          padding: windowDimensions.width < 768 ? "70px 0 0 0" : "0 120px 0 120px",
        }}
      >
        <div style={{padding: "0 20px"}}>
          <StyledSectionHeader
            style={{
              textAlign: "center",
              marginTop: "0",
              paddingTop: "32px",
              fontSize: windowDimensions.width > 768 ? "60px" : "44px",
            }}
          >
            Frequently asked questions
          </StyledSectionHeader>
          <StyledSectionSubHeader
            style={{ textAlign: "center", fontSize: windowDimensions.width > 768 ? "20px" : "14px" }}
          >
            Everything you need to know about the product and billing.
          </StyledSectionSubHeader>
        </div>
        <StyledQuestionsContainer
          $isMobile={windowDimensions.width < 768 ? true : false}
        >
          {/* <StyledQuestionMarkContainer>
            <div>
              <h2>What is Pentugram?</h2>
              <p>
                Pentugram is a tool that helps facilitate and organize the fund
                manager's daily operations along with their teams. Pentugram's
                features allow pipeline management, portfolio management and
                reporting to be efficient and clear, in addition to tasks and
                contacts management and other related features.
              </p>
            </div>
          </StyledQuestionMarkContainer> */}
          <FaqItem
            question="What is Pentugram?"
            answer="Pentugram is a tool that helps facilitate and organize the fund
                manager's daily operations along with their teams. Pentugram's
                features allow pipeline management, portfolio management and
                reporting to be efficient and clear, in addition to tasks and
                contacts management and other related features."
          />
          <FaqItem
            question="Can I customize the tools I use to my needs?"
            answer={
              <>
                <p>Almost everything is customizable!</p>
                <p>
                  Create pipelines with different stages, organize your
                  portfolio the way you want. Data inputs are customizable where
                  you can create your own. Build customizable reports using your
                  data with an editor like any other existing editor.
                </p>
              </>
            }
          />
          <FaqItem
            question="How many pipelines and portfolios can I create?"
            answer={<>As much as you want, go crazy.</>}
          />
          <FaqItem
            question="How safe is my data on Pentugram?"
            answer={
              <>
                <p>
                  Don’t worry, we take data privacy and confidentiality very
                  seriously. We treat your data as confidential and in
                  accordance with our privacy policy. Your data is never
                  transmitted or stored on any third-party servers.
                </p>
                <p>
                  We also implement high standard security measures with a
                  regular checkup and updates. Your data is safe, replicated in
                  real-time with automated daily backups, in addition to weekly
                  offsite backups
                </p>
              </>
            }
          />
          <FaqItem
            question="How can I report my investment activity?"
            answer={
              <>
                <p>
                  Our reporting tool will do the job, where it merges between
                  the normal rich editors with having all the data in its
                  back-office. Building reports is like building a document or a
                  presentation on other tools. Templates saved will let you
                  build your next report in no time.
                </p>
              </>
            }
          />
          <FaqItem
            question="Can I import my data to Pentugram?"
            answer={
              <>
                <p>
                  Absolutely, data can be migrated using excel or pulling it
                  from other platforms. Our team is always ready to support this
                  process.
                </p>
              </>
            }
          />
          <FaqItem
            question="Can I get a free trial?"
            answer={
              <>
                <p>
                  Sure, you will get a 15 days trial period no matter what
                  package you choose
                </p>
              </>
            }
          />
          {/* <StyledQuestionMarkContainer>
            <div>
              <QuestionMarkIcon />
            </div>
            <div>
              <h2>How safe is my data on Pentugram?</h2>
              <p>
                Don’t worry, we take data privacy and confidentiality very
                seriously. We treat your data as confidential and in accordance
                with our privacy policy. Your data is never transmitted or
                stored on any third-party servers.
              </p>
              <p>
                We also implement high standard security measures with a regular
                checkup and updates. Your data is safe, replicated in real-time
                with automated daily backups, in addition to weekly offsite
                backups
              </p>
            </div>
          </StyledQuestionMarkContainer>
          <StyledQuestionMarkContainer>
            <div>
              <QuestionMarkIcon />
            </div>
            <div>
              <h2>Can I customize the tools I use to my needs?</h2>
              <p>Almost everything is customizable!</p>
              <p>
                Create pipelines with different stages, organize your portfolio
                the way you want. Data inputs are customizable where you can
                create your own. Build customizable reports using your data with
                an editor like any other existing editor.
              </p>
            </div>
          </StyledQuestionMarkContainer>
          <StyledQuestionMarkContainer>
            <div>
              <QuestionMarkIcon />
            </div>
            <div>
              <h2>How can I report my investment activity?</h2>
              <p>
                Our reporting tool will do the job, where it merges between the
                normal rich editors with having all the data in its back-office.
                Building reports is like building a document or a presentation
                on other tools. Templates saved will let you build your next
                report in no time.
              </p>
            </div>
          </StyledQuestionMarkContainer>

          <StyledQuestionMarkContainer>
            <div>
              <QuestionMarkIcon />
            </div>
            <div>
              <h2>Can I import my data to Pentugram?</h2>
              <p>
                Absolutely, data can be migrated using excel or pulling it from
                other platforms. Our team is always ready to support this
                process.
              </p>
            </div>
          </StyledQuestionMarkContainer>
          <StyledQuestionMarkContainer>
            <div>
              <QuestionMarkIcon />
            </div>
            <div>
              <h2>How many pipelines and portfolios can I create?</h2>
              <p>As much as you want, go crazy.</p>
            </div>
          </StyledQuestionMarkContainer>
          <StyledQuestionMarkContainer>
            <div>
              <QuestionMarkIcon />
            </div>
            <div>
              <h2>Can I get a free trial?</h2>
              <p>
                Sure, you will get a 15 days trial period no matter what package
                you choose
              </p>
            </div>
          </StyledQuestionMarkContainer> */}
        </StyledQuestionsContainer>
        <StartTrialSection />
      </main>
      <FooterSection />
    </>
  );
}

export default FAQsPage;
